import { OrderedProduct } from 'utils/dto';

export type OrderedProductToEdit = {
  orderedProductId: string;
  newQuantity: number;
  orderedOptionIds: string[];
  productIds: string[];
};

export type SelectedOrderedProduct = {
  orderedProductId: string;
  newQuantity: number;
  orderedOptionIds: string[];
  productIds: string[];
};

export enum BorderType {
  None,
  Primary,
  Secondary,
}

export const getBottomBorder = (
  product: OrderedProduct,
  products: OrderedProduct[],
  index: number
) => {
  const isNextProductCategoryDifferent = product.category !== products[index + 1]?.category;

  const isLastChild = product === products[products.length - 1];

  if (isLastChild) return BorderType.None;
  if (isNextProductCategoryDifferent) return BorderType.Secondary;
  return BorderType.Primary;
};
