import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import { useApi, Method } from 'service/api';
import { CategoriesContext } from 'contexts/Categories';
import { Category } from 'utils/dto';
import { ReactComponent as SortArrow } from 'assets/sort-arrow.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
import { CircularCta } from 'theme/globals';

import { Card, RotatedCircularCta } from './styles';

type Props = {
  category: Category;
  sortBy: 'frontOrder' | 'boOrder';
};

export const CategoryCard = ({ category, sortBy }: Props) => {
  const { setCategoryToEdit, categories, setCategories, setDisplayModal } =
    useContext(CategoriesContext);

  const { shopId }: { shopId: string } = useParams();

  const { sendRequest: updateOrders } = useApi([`shop/${shopId}/categories/edit`], Method.POST);

  const handleEditClick = () => {
    setCategoryToEdit(category);
    setDisplayModal(true);
  };

  const handleSort = (direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? category[sortBy] - 1 : category[sortBy] + 1;

    const sortedCategories = arrayMove(
      categories.sort((a, b) => a[sortBy] - b[sortBy]),
      category[sortBy],
      newIndex
    );

    const newCategories = sortedCategories.map((e, i) => ({ ...e, [sortBy]: i }));

    setCategories(sortedCategories.map((e, i) => ({ ...e, [sortBy]: i })));
    updateOrders(newCategories);
  };

  return (
    <Card>
      {category.name}
      <div>
        <CircularCta onClick={handleEditClick}>
          <EditIcon />
        </CircularCta>

        <RotatedCircularCta onClick={() => handleSort('up')}>
          <SortArrow />
        </RotatedCircularCta>

        <CircularCta onClick={() => handleSort('down')}>
          <SortArrow />
        </CircularCta>
      </div>
    </Card>
  );
};
