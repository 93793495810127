import { createGlobalStyle } from 'styled-components';
import { Colors, Fonts, FontWeight } from '.';

export const GlobalStyle = createGlobalStyle`
* {
    padding: 0;
    margin: 0;
    font-family: ${Fonts.primary};
    color: ${Colors.font};
    font-weight: ${FontWeight.regular};
}

:focus {
    outline: none;
}

body {
    background: ${Colors.lightBackground};
}

html, body, #root{
   height: 100%;
}


button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-align: start;    
    display: block;
}

button:disabled {
    opacity: 0.7;
    pointer-events: none;
}

`;
