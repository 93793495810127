import React, { useContext } from 'react';
import { OrderModalContext } from 'contexts/OrderModal';
import { OrderedProduct } from 'utils/dto';
import { formatPrice } from 'utils/price';
import { OrdersContext } from 'contexts/Orders';
import { sortProductsFunction } from 'utils/product';
import {
  Product,
  ProductInformation,
  Quantity,
  OptionBox,
  Option,
  Name,
  Price,
  IncrementButton,
} from './style';
import { getBottomBorder } from '../OrderModals/content';

type Props = {
  addButton?: boolean;
  paidProducts: 'hidden' | 'muted';
};

export const OrderedProductList = ({ addButton, paidProducts }: Props) => {
  const { relevantOrderUnMerged, selectedProducts, setSelectedProducts } =
    useContext(OrderModalContext);
  const { unMergedOrder } = useContext(OrdersContext);

  if (!unMergedOrder) return null;

  const allOrderedProducts = relevantOrderUnMerged?.orderedProducts || [];

  const orderedProducts = {
    all: allOrderedProducts,
    unPaid: allOrderedProducts.filter((product) => !product.isPaid),
  };

  const getProducts = (): OrderedProduct[] => {
    const filteredProducts =
      paidProducts === 'hidden' ? orderedProducts.unPaid : orderedProducts.all;

    const sortedProducts = filteredProducts.sort((a, b) => sortProductsFunction(a, b));

    return sortedProducts;
  };

  const products = getProducts();

  const handleAddButton = (product: OrderedProduct) => {
    if (!addButton) return null;

    if (!selectedProducts.includes(product))
      return setSelectedProducts((prev) => [...prev, product]);

    const newSelectedProducts = selectedProducts.filter((item) => item !== product);
    return setSelectedProducts(newSelectedProducts);
  };
  return (
    <div>
      {products.map((product, index) => {
        const outputBorderType = getBottomBorder(product, products, index);

        const isProductSelected = selectedProducts.includes(product);

        return (
          <Product
            data-cy={isProductSelected ? 'selected-ordered-product' : 'ordered-product'}
            key={index}
            bottomBorder={outputBorderType}
            isSelected={!!addButton && isProductSelected}
            isClickable={!!addButton}
            isMuted={paidProducts === 'muted' && product.isPaid}
            onClick={() => handleAddButton(product)}
            type="button"
          >
            <ProductInformation data-cy="product-infos">
              <Name>{product.name}</Name>
              {product.quantity > 1 && <Quantity>(x{product.quantity})</Quantity>}
              {product.orderedOptions && product.orderedOptions.length > 0 && (
                <OptionBox>
                  {product.orderedOptions?.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
                </OptionBox>
              )}
            </ProductInformation>

            <Price>
              {formatPrice(product.price * (product.quantity || 1))}
              {addButton && <IncrementButton>{isProductSelected ? '-' : '+'}</IncrementButton>}
            </Price>
          </Product>
        );
      })}
    </div>
  );
};

OrderedProductList.defaultProps = {
  addButton: false,
};
