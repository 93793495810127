import { keyframes } from 'styled-components';

export const shake = keyframes`
 10%, 90% {
    transform: rotate(-3deg);
  }
  20%, 80% {
    transform: rotate(5deg);
  }
  30%, 50%, 70% {
    transform: rotate(-10deg);
  }
  40%, 60% {
    transform: rotate(10deg);
  }
}
`;

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
`;
