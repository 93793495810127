export const splitIntoColumns = <T>(list: Array<T>) => {
  const nbColumns = 4;

  const indexArray: number[][] = Array(list.length)
    .fill(0)
    .map((__, i) => 1 + i)
    .reduce((acc, curr) => {
      acc[(curr - 1) % nbColumns] = [...acc[(curr - 1) % nbColumns], curr];
      return acc;
    }, Array(nbColumns).fill([]));

  const newArray: Array<T>[] = indexArray.map((column) =>
    column.map((index: number) => list[index - 1])
  );

  return newArray;
};

export const hasSameItems = <T>(array1: T[], array2: T[]) => {
  if (array1.length !== array2.length) return false;

  return array1.every((curr) => {
    return array2.includes(curr);
  });
};
