import React, { useContext } from 'react';
import { CenteredModal, Direction } from 'components/CenteredModal';
import { ModalType, OrderModalContext } from 'contexts/OrderModal';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/price';

import { Method, useApi } from 'service/api';
import { computeQuantityEdition } from 'utils/product';
import { useParams } from 'react-router';
import { SummaryProduct } from 'components/SummaryProduct';
import { OrdersContext } from 'contexts/Orders';
import { ButtonsBox, DetailsBox } from './styles';
import { ValidateButton } from '../common-styles';

export const EditValidationModal = () => {
  const { closeModal, displayedModal, productsToEdit, relevantOrder } =
    useContext(OrderModalContext);

  const { refreshOrders } = useContext(OrdersContext);

  const quitModal = () => closeModal();

  const { t } = useTranslation();

  const { shopId }: { shopId: string } = useParams();
  const { sendRequest: sendRemoval } = useApi([`shop/${shopId}/ordered_products`], Method.DELETE);
  const { sendRequest: sendEdition } = useApi([`shop/${shopId}/orders`], Method.POST);

  if (!relevantOrder) return null;

  const productsToDisplay = productsToEdit.map((element) => ({
    ...element,
    product: relevantOrder.orderedProducts.find(
      (productInOrder) => productInOrder.productId === element.orderedProductId
    ),
  }));

  const priceDifference = productsToDisplay.reduce(
    (partialSum, element) => partialSum + element.newQuantity * (element.product?.price || 0),
    0
  );

  const submitEdition = () => {
    const quantityEdition = computeQuantityEdition(productsToEdit);
    const promises: Promise<void>[] = [];

    if (quantityEdition.idsToDelete.length > 0)
      promises.push(sendRemoval(quantityEdition.idsToDelete));

    if (quantityEdition.productsToAdd.length > 0)
      promises.push(
        sendEdition({
          tableNumber: relevantOrder.tableNumber,
          orderedProducts: quantityEdition.productsToAdd,
          specificInstructions: '',
        })
      );

    Promise.all(promises).then(() => {
      refreshOrders();
      closeModal();
    });
  };

  return (
    <CenteredModal
      isModalOpen={displayedModal === ModalType.EditionValidation}
      toggleModal={quitModal}
      direction={Direction.Left}
      title="Souhaitez-vous modifier ces produits ?"
    >
      <div>
        {productsToDisplay.map(
          (product) =>
            product.product && (
              <SummaryProduct
                key={product.orderedProductId}
                product={product.product}
                usage="validation"
              />
            )
        )}

        <DetailsBox>
          <div>
            <p>{t('order.previousTotal')}</p>
            <p>{formatPrice(relevantOrder.totalPrice)}</p>
          </div>

          <div>
            <p>{t('order.changes')}</p>
            <p>
              {priceDifference > 0 ? '+' : ''}
              {formatPrice(priceDifference)}
            </p>
          </div>

          <div className="separator">
            <p>{t('order.newTotal')}</p>
            <p>{formatPrice(relevantOrder.totalPrice + priceDifference)}</p>
          </div>

          <div className="leftToPay">
            <p>{t('leftToPay')}</p>
            <p>{formatPrice(relevantOrder.leftToPay + priceDifference)}</p>
          </div>
        </DetailsBox>

        <ButtonsBox>
          <ValidateButton onClick={closeModal} className="secondary">
            {t('cancel')}
          </ValidateButton>
          <ValidateButton onClick={submitEdition}>Valider</ValidateButton>
        </ButtonsBox>
      </div>
    </CenteredModal>
  );
};
