import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Colors, Fonts, FontSizes, FontWeight } from 'theme';

type ComponentBoxProps = {
  isPaid?: boolean;
};

export const ComponentBox = styled.div<ComponentBoxProps>`
  padding: ${pxToRem(16)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.isPaid ? 0.3 : null)};
  &:not(:first-child) {
    border-top: 1px solid ${Colors.primary};
  }
`;

export const Image = styled.img`
  border-radius: 50%;
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primaryLighter};
  font-size: ${FontSizes.small};
  margin-right: ${pxToRem(16)};
`;

export const DataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: ${pxToRem(24)};
`;

export const Name = styled.span`
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.medium};
  font-weight: ${FontWeight.xBold};
  text-transform: uppercase;
  color: ${Colors.primary};
`;

export const Quantity = styled.span`
  font-family: ${Fonts.primary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.bold};
  color: ${Colors.secondary};
  margin: 0 ${pxToRem(8)};
`;

export const Options = styled.div`
  font-size: ${pxToRem(14)};
  margin-top: ${pxToRem(8)};
`;

export const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h1 {
    font-family: ${Fonts.primary};
    font-size: ${FontSizes.regular};
    font-weight: ${FontWeight.bold};
    white-space: nowrap;
  }

  h2 {
    margin-top: ${pxToRem(4)};
    font-family: ${Fonts.primary};
    font-size: ${FontSizes.small};
    font-weight: ${FontWeight.light};
    white-space: nowrap;
  }
`;

export const IncrementBox = styled.div`
  display: flex;
  align-items: center;

  button {
    height: ${pxToRem(40)};
    width: ${pxToRem(40)};
    min-height: ${pxToRem(40)};
    min-width: ${pxToRem(40)};
    border-radius: 50%;
    background-color: ${Colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.regular};
    font-size: ${FontSizes.title};
    line-height: 28px;
    color: ${Colors.utilityBright};
  }

  p {
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.regular};
    font-size: ${FontSizes.big};
    color: ${Colors.utilityDark};
    margin: 0 ${pxToRem(14)};
  }
`;

export const QuantityValidationBox = styled.div`
  font-size: ${pxToRem(24)};
  font-family: ${Fonts.secondary};
  font-weight: ${FontWeight.light};
`;
