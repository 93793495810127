import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.div`
  margin: ${pxToRem(34)} ${pxToRem(112)};

  h2 {
    text-align: center;
    margin: 0 ${pxToRem(20)} ${pxToRem(25)};
    color: ${Colors.utilityBright};
  }
`;

export const DivWithSeparator = styled.div`
  position: relative;

  ::after {
    content: '';
    position: absolute;
    right: ${pxToRem(-32)};
    top: 1rem;
    width: 2px;
    height: calc(100% - 2rem);
    background-color: ${Colors.utilityBright};
  }
`;

export const AddCategory = styled.div`
  display: flex;
  gap: ${pxToRem(16)};
  align-items: center;
  color: ${Colors.utilityBright};
  margin-bottom: ${pxToRem(43)};
`;

export const Row = styled.div`
  display: flex;
  gap: ${pxToRem(64)};
`;
