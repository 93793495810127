import { usePersistentState } from 'hooks/usePersistentState';
import React, {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { Method, useApi } from 'service/api';
import { AuthData } from 'utils/api-dto';
import { ShopInformation } from 'utils/dto';

export const STORAGE_TOKEN_KEY = 'iorder-token';

export type AuthContextType = {
  token: string | null;
  shopList: ShopInformation[];
  currentShop?: ShopInformation;
  setAuthData: (data: AuthData) => void;
  resetAuthData: () => void;
  setCurrentShop: Dispatch<SetStateAction<ShopInformation | undefined>>;
};

const defaultValue: AuthContextType = {
  token: null,
  shopList: [],
  currentShop: undefined,
  setAuthData: () => null,
  resetAuthData: () => null,
  setCurrentShop: () => null,
};

export enum Rights {
  Order = 'order',
  Pay = 'pay',
}

export const AuthContext = createContext<AuthContextType>(defaultValue);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = usePersistentState<string | null>(
    STORAGE_TOKEN_KEY,
    defaultValue.token
  );
  const [shopList, setShopList] = useState(defaultValue.shopList);
  const [currentShop, setCurrentShop] = useState(defaultValue.currentShop);

  const { data: authResponse, sendRequest, status } = useApi<AuthData>(['fast-login'], Method.POST);

  const setAuthData = (data: AuthData) => {
    setToken(data.session.token);
    setShopList(data.shopsInformation);

    if (data.shopsInformation.length > 0) setCurrentShop(data.shopsInformation[0]);
  };

  useEffect(() => {
    // fast login
    if (!!token && !currentShop) sendRequest(token);
  }, []);

  useEffect(() => {
    if (status === 202) setAuthData(authResponse);
  }, [status]);

  const resetAuthData = () => {
    setToken(defaultValue.token);
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    setShopList(defaultValue.shopList);
    setCurrentShop(defaultValue.currentShop);
  };

  const value: AuthContextType = {
    token,
    shopList,
    currentShop,
    setAuthData,
    resetAuthData,
    setCurrentShop,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
