import styled from 'styled-components';
import { Colors, FontWeight } from 'theme';
import { Cta } from 'theme/globals';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${pxToRem(20)};
  column-gap: ${pxToRem(30)};
  margin-top: ${pxToRem(30)};

  input[type='text'],
  input[type='number'] {
    all: unset;
    padding: ${pxToRem(4)} 0;
    width: 100%;
    border-bottom: 1px solid ${Colors.primaryLighter};

    font-size: ${pxToRem(16)};
    font-weight: ${FontWeight.regular};
  }

  .error {
    color: ${Colors.error};
  }

  .full-width {
    grid-column: span 2 !important;
  }

  .price {
    border-bottom: 1px solid ${Colors.primaryLighter};
    display: flex;
    align-items: center;

    input {
      border-bottom: none;
    }
  }
`;

export const SubmitButton = styled(Cta)`
  margin: 0 auto;
  grid-column: span 2;
  font-size: ${pxToRem(20)};
`;
