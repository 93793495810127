import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, getOrderedProductsPrice } from 'utils/price';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { CenteredModal } from 'components/CenteredModal';
import { OrderedProductList } from 'components/OrderedProductList';
import { IncrementButton } from 'components/OrderedProductList/style';
import { Cta } from 'theme/globals';
import { AllPrice, LeftToPay, ButtonBox, ContentWrapper } from './style';
import { PaymentDataBox, ProgressBar, Separator } from '../common-styles';

export const SelectedProductModal = () => {
  const {
    displayedModal,
    relevantOrderUnMerged,
    closeModal,
    selectedProducts,
    setSelectedProducts,
    toggleModal,
    setProductsToPayPrice,
  } = useContext(OrderModalContext);

  const { t } = useTranslation();

  const currentPriceToPay = getOrderedProductsPrice(selectedProducts);

  const leftToPay = relevantOrderUnMerged && relevantOrderUnMerged.leftToPay - currentPriceToPay;

  useEffect(() => {
    setProductsToPayPrice(currentPriceToPay);
  }, [leftToPay]);

  if (!relevantOrderUnMerged) return null;

  const isButtonDisabled = selectedProducts.length < 1;

  const areAllProductsSelected =
    selectedProducts.length ===
    relevantOrderUnMerged?.orderedProducts.filter((product) => !product.isPaid).length;

  return (
    <CenteredModal
      isModalOpen={displayedModal === ModalType.SelectProduct}
      toggleModal={closeModal}
      title="Articles à régler"
      noPadding
    >
      <>
        <ProgressBar progression="30%" />

        <ContentWrapper>
          <div>
            <AllPrice
              data-cy="select-all"
              type="button"
              onClick={() =>
                areAllProductsSelected
                  ? setSelectedProducts([])
                  : setSelectedProducts(
                      relevantOrderUnMerged.orderedProducts.filter((product) => !product.isPaid)
                    )
              }
            >
              <LeftToPay>
                <p>{t('selectAll')}</p>
                <p>{formatPrice(relevantOrderUnMerged?.leftToPay)}</p>
              </LeftToPay>

              <IncrementButton> {areAllProductsSelected ? '-' : '+'}</IncrementButton>
            </AllPrice>
            <OrderedProductList paidProducts="hidden" addButton />
          </div>

          <PaymentDataBox>
            <div>
              <p>{t('orderTotal')}</p>
              <p>{formatPrice(relevantOrderUnMerged?.totalPrice)}</p>
            </div>
            <Separator />
            <div>
              <p>{t('payment')}</p>
              <p>{currentPriceToPay ? formatPrice(currentPriceToPay) : '0 €'}</p>
            </div>
          </PaymentDataBox>

          <ButtonBox>
            <Cta isEmpty onClick={() => toggleModal(ModalType.Summary)}>
              {t('cancel')}
            </Cta>
            <Cta
              id="pay-button"
              disabled={isButtonDisabled}
              onClick={() => toggleModal(ModalType.Invoice)}
            >
              {t('validateOrder')}
            </Cta>
          </ButtonBox>
        </ContentWrapper>
      </>
    </CenteredModal>
  );
};
