import styled from 'styled-components';
import { spin } from 'theme';
import { pxToRem } from 'utils/style-utils';

type LoaderProps = {
  diameter: number;
  colors: {
    primary: string;
    secondary: string;
  };
};

export const Loader = styled.div<LoaderProps>`
  border: ${(props) => pxToRem(props.diameter / 4)} solid ${(props) => props.colors.secondary};
  border-top: ${(props) => pxToRem(props.diameter / 4)} solid ${(props) => props.colors.primary};
  border-radius: 50%;
  width: ${(props) => pxToRem(props.diameter)};
  height: ${(props) => pxToRem(props.diameter)};
  animation: ${spin} 1.5s ease infinite;
`;
