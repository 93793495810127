import React from 'react';
import { Table } from 'components/Table';
import { Order } from 'utils/dto';
import { formatPrice } from 'utils/price';

import { mergeProducts } from 'utils/product';
import { HISTORY_PRODUCT_COLUMNS } from './content';
import { CellComponent, PriceRow } from './styles';

type Props = { order: Order };

export const ExpandedHistoryOrder = ({ order }: Props) => {
  return (
    <CellComponent colSpan={5}>
      <Table columns={HISTORY_PRODUCT_COLUMNS} data={mergeProducts(order.orderedProducts)} />

      <PriceRow>
        <p>Prix Total</p>
        <p>{formatPrice(order.totalPrice)}</p>
      </PriceRow>
    </CellComponent>
  );
};
