import styled from 'styled-components';
import { Colors } from 'theme';
import { CircularCta } from 'theme/globals';
import { pxToRem } from 'utils/style-utils';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const Card = styled.div`
  padding: ${pxToRem(16)};
  border: 2px solid ${Colors.primary};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.utilityBright};
  color: ${Colors.utilityDark};

  div {
    display: flex;
    gap: ${pxToRem(16)};
    margin-left: ${pxToRem(16)};
  }
`;

export const RotatedCircularCta = styled(CircularCta)`
  transform: rotate(180deg);
`;
