import { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export const useSuggestionField = (isRequired: boolean) => {
  const [displaySuggestions, setDisplaySuggestions] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const formMethods = useFormContext();

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (target && ref.current && !ref.current.contains(target as Node))
        setDisplaySuggestions(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const inputProps = {
    type: 'text',
    autoComplete: 'off',
    onClick: () => setDisplaySuggestions(true),
    required: isRequired,
  };

  return { displaySuggestions, setDisplaySuggestions, ref, inputProps, formMethods };
};
