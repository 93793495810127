import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Slide as MuiSlide } from '@mui/material';
import { withStyles } from '@mui/styles';
import { pxToRem } from 'utils/style-utils';
import { Colors, Fonts, FontSizes, FontWeight } from 'theme';
import { ReactComponent as CloseSvg } from 'assets/ExitCross.svg';

export const ModalStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Slide = withStyles({
  root: {
    outline: 'none',
    margin: pxToRem(16),
    maxWidth: '80vw',
    borderRadius: 20,
    maxHeight: '90vh',
    overflow: 'auto',
  },
})(MuiSlide);

export const HeaderBox = styled.div`
  display: flex;
  column-gap: ${pxToRem(32)};
  padding: ${pxToRem(24)} ${pxToRem(24)} 0 ${pxToRem(24)};
  align-items: center;

  div {
    display: flex;
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.regular};
    font-size: ${FontSizes.regular};
    line-height: 19px;

    color: ${Colors.secondary};
  }
  b {
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.bold};
    font-size: ${FontSizes.regular};
    line-height: 19px;

    color: ${Colors.secondary};
    padding-left: ${pxToRem(8)};
  }
`;

export const HeaderTitle = styled.span`
  width: 100%;
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.big};
  font-weight: ${FontWeight.light};
`;

export const getBodyStyle = (noPadding = false): CSSProperties => ({
  padding: noPadding ? 0 : `0 ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)}`,
});

export const CloseCrossIcon = styled(CloseSvg)`
  width: ${pxToRem(16)};
  height: ${pxToRem(24)};
  fill: ${Colors.font};
`;
