import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { AuthContextProvider } from 'contexts/Auth';
import { BackofficeLayout } from './pages/BackofficeLayout';
import { LoginPage } from './pages/LoginPage';
import { GlobalStyle } from './theme/global-style';
import 'react-toastify/dist/ReactToastify.min.css';
import './translations/i18n';

export const App = () => (
  <BrowserRouter>
    <HelmetProvider>
      <GlobalStyle />

      <AuthContextProvider>
        <Route exact path="/" component={LoginPage} />
        <Route path="/backoffice/:shopId" component={BackofficeLayout} />
      </AuthContextProvider>

      <Route exact path="/:unknownRoute">
        <Redirect to="/" />
      </Route>

      <ToastContainer
        newestOnTop
        pauseOnFocusLoss
        position="bottom-right"
        draggable={false}
        theme="colored"
      />
    </HelmetProvider>
  </BrowserRouter>
);
