import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredModal, Direction } from 'components/CenteredModal';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { formatPrice } from 'utils/price';
import { Cta } from 'theme/globals';
import { Component, ButtonsBox, ButtonWrapper } from './styles';
import { PaymentDataBox, ProgressBar, Separator } from '../common-styles';

export const SplitBillModal = () => {
  const {
    relevantOrder: order,
    closeModal,
    toggleModal,
    displayedModal,
  } = useContext(OrderModalContext);

  const [peopleAtTheTable, setPeopleAtTheTable] = useState(3);
  const [payFor, setPayFor] = useState(1);

  const { t } = useTranslation();

  if (!order) return null;

  const amountToPay = (order.leftToPay / peopleAtTheTable) * payFor;

  const onButtonClick = () => {
    // TODO: pay: Math.floor(amountToPay)
  };

  const onPayForClick = (action: 'inc' | 'decr') => {
    if (action === 'decr') {
      if (payFor <= 1) return null;

      return setPayFor((prev) => prev - 1);
    }

    if (payFor < peopleAtTheTable) return setPayFor((prev) => prev + 1);

    return null;
  };

  const editPeopleAtTheTable = (action: 'inc' | 'decr') => {
    if (action === 'decr') {
      if (peopleAtTheTable <= 1) return null;

      if (payFor > peopleAtTheTable - 1) setPayFor(peopleAtTheTable - 1);

      return setPeopleAtTheTable((prev) => prev - 1);
    }

    return setPeopleAtTheTable((prev) => prev + 1);
  };

  return (
    <CenteredModal
      isModalOpen={displayedModal === ModalType.SplitBill}
      toggleModal={closeModal}
      direction={Direction.Left}
      title={t('splitBill')}
      noPadding
    >
      <>
        <ProgressBar progression="30%" />

        <Component>
          <h1>{t('peopleAtTheTable')}</h1>
          <ButtonsBox>
            <button type="button" onClick={() => editPeopleAtTheTable('decr')}>
              -
            </button>
            <h2>{peopleAtTheTable}</h2>
            <button type="button" onClick={() => editPeopleAtTheTable('inc')}>
              +
            </button>
          </ButtonsBox>

          <h1>{t('payFor')}</h1>
          <ButtonsBox>
            <button type="button" onClick={() => onPayForClick('decr')}>
              -
            </button>
            <h2>{payFor}</h2>
            <button type="button" onClick={() => onPayForClick('inc')}>
              +
            </button>
          </ButtonsBox>

          <PaymentDataBox>
            <div>
              <p>{t('orderTotal')}</p>
              <p>{formatPrice(order.leftToPay)}</p>
            </div>
            <Separator />
            <div>
              <p>{t('payment')}</p>
              <p>{formatPrice(amountToPay)}</p>
            </div>
          </PaymentDataBox>

          <ButtonWrapper>
            <Cta isEmpty onClick={() => toggleModal(ModalType.Summary)}>
              {t('cancel')}
            </Cta>
            <Cta onClick={onButtonClick}>{t('paymentValidate')}</Cta>
          </ButtonWrapper>
        </Component>
      </>
    </CenteredModal>
  );
};
