import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Table } from 'components/Table';
import { Method, useApi } from 'service/api';
import { Order } from 'utils/dto';
import { Page } from './styles';
import { HISTORY_COLUMNS } from './columns';

export const HistoryPage = () => {
  const { shopId }: { shopId: string } = useParams();

  const { data: orders, sendRequest: refreshOrders } = useApi<Order[]>(
    [`shop/${shopId}/order-history?limit=100&offset=0`],
    Method.GET
  );

  useEffect(() => {
    const intervalId = setTimeout(() => {
      refreshOrders();
    }, 2000);

    return () => clearTimeout(intervalId);
  });

  return (
    <Page>
      <Table columns={HISTORY_COLUMNS} data={orders} hasChildRows />
    </Page>
  );
};
