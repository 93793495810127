import styled from 'styled-components';
import { pxToRem, withOpacity } from 'utils/style-utils';
import { Colors, FontSizes, Fonts } from 'theme';

export const ContentWrapper = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)};
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
`;

export const AllPrice = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(16)} ${pxToRem(16)} 0;
  border-bottom: solid 2px ${Colors.secondary};

  font-family: ${Fonts.primary};
  font-size: ${FontSizes.regular};
  text-transform: uppercase;
  color: ${withOpacity(Colors.utilityDark, 0.8)};
`;

export const LeftToPay = styled.div`
  display: flex;
  gap: ${pxToRem(16)};
  font-family: ${Fonts.secondary};
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${pxToRem(40)};
`;
