import styled from 'styled-components';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { modalContentBox } from '../common-styles';

export const Component = styled.div`
  ${modalContentBox}
  gap: ${pxToRem(24)};

  h1 {
    font-family: ${Fonts.secondary};
    font-size: ${FontSizes.big};
    font-weight: ${FontWeight.light};
    text-align: center;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: ${pxToRem(40)};
    width: ${pxToRem(40)};
    background: ${Colors.secondary};
    border-radius: 50%;
    text-align: center;
    color: ${Colors.utilityBright};

    font-family: ${Fonts.primary};
    font-size: ${FontSizes.title};
    font-weight: ${FontWeight.light};
  }

  h2 {
    font-family: ${Fonts.secondary};
    font-size: ${FontSizes.title};
    font-weight: ${FontWeight.light};
    margin: 0 ${pxToRem(15)};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${pxToRem(40)};
`;
