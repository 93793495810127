import styled from 'styled-components';
import { Colors, Fonts, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { ReactComponent as ExpendArrowSVG } from 'assets/expend-arrow.svg';
import { ReactComponent as SortArrowSvg } from 'assets/sort-arrow.svg';

export const Component = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: ${pxToRem(24)};
  background: ${Colors.utilityBright};

  td,
  th {
    padding: 0.5rem;
  }

  th {
    cursor: pointer;
    white-space: nowrap;
  }

  tr:nth-child(even) {
    background: ${Colors.primaryLighter};
  }

  th {
    padding: 0.5rem 1rem;
    text-align: left;
    text-transform: uppercase;
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.bold};
    background: ${Colors.primary};
    color: ${Colors.utilityBright};
  }
`;

export const SortArrow = styled(SortArrowSvg)<{ sortOrder?: 'asc' | 'desc' }>`
  margin-left: 0.8rem;
  transform: scale(0.6) ${(props) => (props.sortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const NoRows = styled.tr`
  width: 100%;
  text-align: center;
  font-style: italic;
`;

export const ExpendArrow = styled(ExpendArrowSVG)<{ isRotated: boolean }>`
  width: ${pxToRem(17)};
  transition: transform 200ms ease-in-out;
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
