import styled from 'styled-components';
import { Colors, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const CellComponent = styled.td`
  border-bottom: 1px solid ${Colors.secondary};
  padding: 0 !important;
`;

export const PriceRow = styled.div`
  display: flex;
  gap: ${pxToRem(32)};

  padding: 0.5rem 1rem;
  border-top: 1px solid ${Colors.primary};

  p {
    font-weight: ${FontWeight.bold};
    text-transform: uppercase;
  }
`;
