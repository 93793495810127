export enum Colors {
  primary = '#153462',
  primaryLighter = '#EDEDED',
  secondary = '#7B217E',
  font = '#3C3C3B',
  lightFont = ' #3c3d3c',
  utilityDark = '#3C3C3B',
  utilityBright = '#FAFAFA',
  error = '#DD102D',
  validate = '#6ABD93',

  fontLighter = '#7D7D7D',
  lightBackground = '#F9F9F9',
  darkBackground = '#323232',
}
