import { ReactNode } from 'react';

export type Column<T extends object> = {
  header: string;
  accessor?: keyof T;
  transform: (cell: T) => ReactNode;
};

export type SortType<T extends object> = { key: keyof T; order: 'asc' | 'desc' } | undefined;

export const sortDataFunction = <T extends object>(a: T, b: T, sortBy: SortType<T>) => {
  if (!sortBy) return 0;

  if (sortBy.order === 'asc') return a[sortBy.key] > b[sortBy.key] ? 1 : -1;

  return a[sortBy.key] < b[sortBy.key] ? 1 : -1;
};

export const getSortingValue = <T extends object>(
  columnKey: keyof T,
  sortBy: SortType<T>
): SortType<T> => {
  if (columnKey !== sortBy?.key) return { key: columnKey, order: 'asc' };

  if (sortBy?.order === 'asc') return { key: columnKey, order: 'desc' };

  return undefined;
};

export const isExtended = <T extends object & { id: string }>(expandedRows: T[], row: T) =>
  expandedRows.map((r) => r.id).includes(row.id);
