import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { ReactComponent as ServedIconSvg } from 'assets/ServeIcon.svg';
import { Cta } from 'theme/globals';

export const Container = styled.div`
  display: flex;
  gap: ${pxToRem(16)};
`;

export const Button = styled(Cta)<{ isSelected?: boolean }>`
  opacity: ${(props) => (props.isSelected ? 1 : 0.3)};
  padding: 0 ${pxToRem(4)};
  text-align: left;
`;

export const ServedIcon = styled(ServedIconSvg)`
  height: ${pxToRem(24)};
  width: ${pxToRem(32)};
  padding-right: ${pxToRem(8)};
`;
