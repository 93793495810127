import styled from 'styled-components';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const ProductWrapper = styled.div<{ bottomBorder: boolean }>`
  position: relative;

  :after {
    content: '';
    display: ${(props) => (props.bottomBorder ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: ${pxToRem(16)};
    width: calc(100% - ${pxToRem(32)});
    height: 2px;
    background-color: ${Colors.secondary};
  }

  &:last-of-type {
    :after {
      content: '';
      display: none;
    }
  }
`;

export const Product = styled.div`
  padding: ${pxToRem(8)} ${pxToRem(16)};

  color: ${Colors.font};
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.xBold};
  text-transform: uppercase;

  &:last-of-type {
    border: 0;
  }
`;

export const Quantity = styled.span`
  padding-right: ${pxToRem(8)};

  color: ${Colors.secondary};
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.xBold};
`;

export const OptionBox = styled.ul`
  margin-left: ${pxToRem(16)};
  padding-top: ${pxToRem(4)};
`;

export const Option = styled.li`
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.regular};
  line-height: ${pxToRem(22)};
  text-transform: none;
`;
