import { CSSProperties } from 'react';
import { Slide as MuiSlide } from '@mui/material';
import { withStyles } from '@mui/styles';
import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Colors, FontSizes, FontWeight } from 'theme';

export const ModalStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Slide = withStyles({
  root: {
    backgroundColor: Colors.lightBackground,
    outline: 'none',
    maxWidth: pxToRem(288),
    borderRadius: pxToRem(20),
    padding: `${pxToRem(32)} ${pxToRem(24)}`,
  },
})(MuiSlide);

export const Text = styled.div`
  color: ${Colors.font};
  font-size: ${FontSizes.big};
  font-weight: ${FontWeight.bold};
  text-align: center;
  margin: 0 ${pxToRem(32)};
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${pxToRem(32)};
  margin-top: ${pxToRem(32)};
`;

type ButtonProps = {
  noBackground?: boolean;
};

export const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.noBackground ? Colors.lightBackground : Colors.primary)};
  border-radius: ${pxToRem(5)};
  border: 2px solid ${(props) => props.noBackground && Colors.primary};
  padding: ${pxToRem(8)} ${pxToRem(32)};
  display: flex;
  justify-content: center;

  color: ${(props) => (props.noBackground ? Colors.primary : Colors.utilityBright)};
  font-size: ${FontSizes.big};
  font-weight: ${FontWeight.bold};
  text-align: center;
`;

export const ErrorMessage = styled.div`
  margin-top: ${pxToRem(16)};
  color: ${Colors.error};
  font-size: ${FontSizes.small};
  text-align: center;
`;
