import React, { useContext, useState } from 'react';
import { formatPrice } from 'utils/price';
import { formatOptions } from 'utils/option';
import { OrderedProduct } from 'utils/dto';
import { OrderModalContext } from 'contexts/OrderModal';
import {
  ComponentBox,
  Image,
  DataBox,
  Name,
  Quantity,
  Options,
  PriceBox,
  IncrementBox,
  QuantityValidationBox,
} from './styles';

type CartProductProps = {
  product: OrderedProduct;
  usage: 'edition' | 'information' | 'validation';
};

export const SummaryProduct = ({ product, usage }: CartProductProps) => {
  const { productsToEdit, setProductsToEdit } = useContext(OrderModalContext);
  const [quantity, setQuantity] = useState<number>(product.quantity);

  const newQuantityDifference = productsToEdit.find(
    (element) => element.orderedProductId === product.productId
  )?.newQuantity;

  const isEditionMode = usage === 'edition' && !product.isPaid;
  const isInformationMode = usage === 'information';
  const isValidationMode = usage === 'validation' && newQuantityDifference;

  const quantityToDisplay = isValidationMode
    ? product.quantity + newQuantityDifference
    : product.quantity;

  const handleIncrementCLick = (button?: 'add' | 'remove') => {
    const isProductIdExist = productsToEdit.find(
      (productToChange) => productToChange.orderedProductId === product.productId
    );
    const newQuantity = button === 'add' ? quantity + 1 : quantity - 1;

    const quantityDifference = newQuantity - product.quantity;

    setQuantity(newQuantity);
    if (isProductIdExist) {
      isProductIdExist.newQuantity = quantityDifference;
    } else {
      productsToEdit?.push({
        orderedProductId: product.productId,
        productIds: product.productIds,
        newQuantity: quantityDifference,
        orderedOptionIds: product.orderedOptions.map((option) => option.id),
      });
    }
    setProductsToEdit([...productsToEdit]);
  };

  return (
    <ComponentBox isPaid={product.isPaid}>
      {product.pictureUrl && <Image alt={`${product.name} image`} src={product.pictureUrl} />}

      <DataBox>
        <div>
          <Name data-cy="product-name">
            {product.name}
            {quantityToDisplay > 1 && <Quantity>(x{quantityToDisplay})</Quantity>}
          </Name>
          <Options>{formatOptions(product.orderedOptions)}</Options>
        </div>

        {isEditionMode && (
          <IncrementBox>
            <button
              disabled={quantity === 0}
              onClick={() => handleIncrementCLick('remove')}
              type="button"
            >
              -
            </button>
            <p>{quantity}</p>
            <button type="button" onClick={() => handleIncrementCLick('add')}>
              +
            </button>
          </IncrementBox>
        )}

        {isInformationMode && (
          <PriceBox>
            <h1>{formatPrice(product.price * product.quantity)}</h1>
            {product.quantity > 1 && <h2>{`(${formatPrice(product.price)}/u)`}</h2>}
          </PriceBox>
        )}

        {isValidationMode && (
          <QuantityValidationBox>{`${
            newQuantityDifference > 0 ? '+' : ''
          }${newQuantityDifference}`}</QuantityValidationBox>
        )}
      </DataBox>
    </ComponentBox>
  );
};
