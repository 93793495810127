/* eslint-disable @typescript-eslint/no-explicit-any */

import { useContext, useEffect, useState } from 'react';
import { AuthContext, STORAGE_TOKEN_KEY } from 'contexts/Auth';
import { ToasterContext } from 'contexts/Toaster';
import { useTranslation } from 'react-i18next';

const apiUrl = `${process.env.REACT_APP_API_URI}backshop/`;

type ResponseType = {
  isLoading: boolean;
  data: any;
  status: number | undefined;
  error: Error | undefined;
};

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

const defaultResponse: ResponseType = {
  isLoading: false,
  data: undefined,
  status: 0,
  error: undefined,
};

type OutputType<T> = {
  sendRequest: (body?: any) => Promise<any>;
  isLoading: boolean;
  status: number | undefined;
  data: T;
  error: Error | undefined;
  resetResponse: () => void;
};

export const useApi = <T,>(
  urls: string[],
  method: Method,
  isFileRequest?: boolean
): OutputType<T> => {
  const { t } = useTranslation();

  const [response, setResponse] = useState<ResponseType>(defaultResponse);
  const { token, resetAuthData } = useContext(AuthContext);
  const { displayError } = useContext(ToasterContext);

  const resetResponse = () => setResponse(defaultResponse);

  const sendRequest = async (body?: any) => {
    setResponse({ ...response, isLoading: true });

    // console.log(`making a ${method} request...`);

    let data: any;
    let status = 0;
    let error: any;

    const getBody = () => {
      if (!isFileRequest) return JSON.stringify(body);

      const formData = new FormData();
      formData.append('file', body);

      return formData;
    };

    const requestOptions: RequestInit = {
      method,
      body: getBody(),
    };

    Promise.all(
      urls.map((url) =>
        fetch(`${apiUrl}${url}`, {
          ...requestOptions,
          headers: new Headers({
            ...(!isFileRequest && { 'Content-Type': 'application/json' }),
            ...(token && { [STORAGE_TOKEN_KEY]: token }),
          }),
        })
          .then((res) => {
            status = res.status;

            if (status === 401) resetAuthData();

            if (status >= 400 && status < 600) {
              displayError(t(`error.${status}`));
            }

            return res.json();
          })
          .then((result) => {
            data = result;
          })
          .catch((exception: Error) => {
            displayError(exception.message);
            error = exception;
          })
          .finally(() => {
            setResponse({ isLoading: false, status, data, error });
          })
      )
    );

    return data;
  };

  useEffect(() => {
    if (method === Method.GET) sendRequest();
  }, []);

  return {
    sendRequest,
    isLoading: response.isLoading,
    status: response.status,
    data: response.data,
    error: response.error,
    resetResponse,
  };
};
