import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Paper } from '@mui/material';
import { OrdersContext } from 'contexts/Orders';
import { ModalType, OrderModalContext } from 'contexts/OrderModal';
import { Method, useApi } from 'service/api';
import { LoadingWheel } from 'components/LoadingWheel';
import { ModalStyles, Slide, Text, ButtonsBox, Button, ErrorMessage } from './styles';

export const CancelOrderModal = () => {
  const { refreshOrders } = useContext(OrdersContext);
  const { setRelevantOrder, relevantOrder, displayedModal, closeModal } =
    useContext(OrderModalContext);

  const { shopId }: { shopId: string } = useParams();

  const needsHelp = relevantOrder?.id.includes('nh');

  const { sendRequest, isLoading, status, resetResponse, error } = useApi(
    needsHelp
      ? [`shop/${shopId}/table/${relevantOrder?.tableNumber}/help`]
      : [`shop/${shopId}/order/${relevantOrder?.id}`],
    needsHelp ? Method.DELETE : Method.POST
  );

  const { t } = useTranslation();

  const quitModal = () => {
    resetResponse();
    setRelevantOrder(null);
    closeModal();
  };

  const confirmRemoval = () => sendRequest({ isCanceled: true });

  useEffect(() => {
    if (status === 201 || status === 202) {
      refreshOrders();
      quitModal();
    }
  }, [status]);

  return (
    <Modal open={displayedModal === ModalType.Cancel} onClose={quitModal} style={ModalStyles}>
      <Slide direction="up" in={displayedModal === ModalType.Cancel}>
        <Paper>
          <Text>{t('cancelOrderValidation')}</Text>
          <ButtonsBox>
            <Button noBackground onClick={quitModal}>
              {t('no')}
            </Button>
            <Button onClick={confirmRemoval} disabled={isLoading}>
              {isLoading ? <LoadingWheel diameter={16} /> : t('yes')}
            </Button>
          </ButtonsBox>
          {error && <ErrorMessage>{t('errorOccurred')}</ErrorMessage>}
        </Paper>
      </Slide>
    </Modal>
  );
};
