import React from 'react';
import i18n from 'i18next';
import { Column } from 'components/Table/content';
import { Order } from 'utils/dto';
import { formatDay, formatTime } from 'utils/time';
import { Colors } from 'theme';
import { Status } from './styles';

const getStatus = (isPaid: boolean, isServed: boolean) => {
  if (isPaid) return { text: i18n.t('status.payed'), color: Colors.validate };

  if (isServed) return { text: i18n.t('status.served'), color: Colors.secondary };

  return { text: i18n.t('status.ongoing'), color: Colors.primary };
};

export const HISTORY_COLUMNS: Column<Order>[] = [
  {
    header: 'order.table',
    accessor: 'tableNumber',
    transform: (order) => order.tableNumber,
  },
  {
    header: 'date',
    accessor: 'createdAt',
    transform: (order) => formatDay(new Date(order.createdAt)),
  },
  {
    header: 'time',
    accessor: 'createdAt',
    transform: (order) => formatTime(new Date(order.createdAt)),
  },
  {
    header: 'status.status',
    accessor: 'isPaid',
    transform: (order) => {
      const status = getStatus(order.isPaid, order.isServed);

      return <Status bg={status.color}>{status.text}</Status>;
    },
  },
];
