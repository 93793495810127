import styled, { css } from 'styled-components';

import { HTMLMotionProps } from 'framer-motion';

const DURATION = 0.2;

type SummaryProps = {
  isOpen?: boolean;
};

const getTransition = (deg: 90 | -90 | 180 | -180, isOpen?: boolean) => css`
  transform: rotate(${isOpen ? deg : 0}deg);
  transition: transform ${DURATION}s ease-in-out;
`;

export const Summary = styled.button<SummaryProps>`
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;

  .rotate-180 {
    ${(props) => getTransition(180, props.isOpen)}
  }
  .rotate-90 {
    ${(props) => getTransition(90, props.isOpen)}
  }
  .rotate--180 {
    ${(props) => getTransition(-180, props.isOpen)}
  }
  .rotate--90 {
    ${(props) => getTransition(-90, props.isOpen)}
  }
`;

export const animationProps: HTMLMotionProps<'div'> = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  },
  transition: { ease: 'easeInOut', duration: DURATION },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};
