import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrdersContext } from 'contexts/Orders';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { useApi, Method } from 'service/api';
import { Order } from 'utils/dto';
import { LoadingWheel } from 'components/LoadingWheel';
import { Colors } from 'theme';
import { FilterContext } from 'contexts/Filter';
import { getOrdersByServed } from 'utils/order';
import { ButtonsBox, Button, ServeIcon, PayIcon } from './styles';

type ButtonsSectionType = {
  allProductsServed: boolean;
  order: Order;
};

export const ButtonsSection = ({ allProductsServed, order }: ButtonsSectionType) => {
  const { t } = useTranslation();
  const { isServedFilter } = useContext(FilterContext);

  const { refreshOrders, unMergedOrder } = useContext(OrdersContext);
  const { setRelevantOrder, setRelevantOrderUnMerged, toggleModal } = useContext(OrderModalContext);

  const { shopId }: { shopId: string } = useParams();

  const { sendRequest, isLoading, status, resetResponse } = useApi(
    [`shop/${shopId}/order/${order.id}`], // update order (isServed = true)
    Method.POST
  );

  useEffect(() => {
    if (status === 201) {
      resetResponse();
      refreshOrders();
    }
  }, [status]);

  const handleClick = () => {
    setRelevantOrder(order);
    const filteredOrders = getOrdersByServed(unMergedOrder, isServedFilter);
    filteredOrders.map((orderUnMerged) => setRelevantOrderUnMerged(orderUnMerged));
    toggleModal(ModalType.Summary);
  };

  return (
    <ButtonsBox>
      {order.orderedProducts.length === 0 ? (
        <p>{t('noProducts')}</p>
      ) : (
        <>
          <Button
            data-cy="serve"
            disabled={allProductsServed || isLoading}
            onClick={() => sendRequest({ isServed: true })}
            bg={Colors.utilityDark}
          >
            {isLoading ? <LoadingWheel diameter={16} /> : <ServeIcon />}
          </Button>
          <Button
            disabled={order.isPaid}
            data-cy="pay"
            onClick={handleClick}
            bg={Colors.utilityDark}
          >
            <PayIcon />
          </Button>
        </>
      )}
    </ButtonsBox>
  );
};
