import { Product } from 'utils/dto';
import { CreateProduct } from 'utils/api-dto';

// FIXME: le nom de cette fonction n'est pas clair (on dirait un fromCreateP plutôt qu'un toCreateP)
export const productFormToCreateProduct = (data: CreateProduct, shopId: string) => {
  const optionCategories = data.optionCategories.map((category) => ({
    ...category,
    options: category.options.map((option) => ({
      ...option,
      additionalPrice: Math.trunc((option.additionalPrice || 0) * 100),
    })),
  }));

  const apiProduct = {
    ...data,
    shopId,
    price: Math.trunc((data.price || 0) * 100),
    optionCategories,
  };

  return apiProduct;
};

// FIXME: le nom de cette fonction n'est pas très clair, peut-être apiProductToCreateProduct ?
export const getProductToCreateProduct = (apiProduct: Product | CreateProduct): CreateProduct => {
  const optionCategories = apiProduct.optionCategories.map((category) => ({
    ...category,
    options: category.options.map((option) => ({
      ...option,
      additionalPrice: (option.additionalPrice || 0) / 100,
    })),
  }));

  const formProduct = {
    ...apiProduct,
    price: (apiProduct.price || 0) / 100,
    optionCategories,
    categoryId: apiProduct.category?.id,
  };

  return formProduct;
};
