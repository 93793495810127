import { CreateProduct } from 'utils/api-dto';

export const DEFAULT_PRODUCT: CreateProduct = {
  id: undefined,
  name: '',
  pictureUrl: '',
  description: '',
  categoryId: '',
  category: { id: '', name: '' },
  subcategory: '',
  isAlcohol: false,
  isAvailable: true,
  price: 0,
  optionCategories: [],
};
