import styled from 'styled-components';
import { Colors, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const DetailsBox = styled.div`
  margin: ${pxToRem(16)} 0;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${pxToRem(16)};
  }

  p {
    text-transform: uppercase;
    opacity: 0.8;
  }

  .separator {
    border-bottom: 2px solid ${Colors.secondary};
    padding-bottom: ${pxToRem(16)};
  }

  .leftToPay {
    p {
      opacity: 1;
      font-weight: ${FontWeight.bold};
    }
  }
`;

export const ButtonsBox = styled.div`
  /* background: lightblue; */
  display: flex;
  margin: 0 ${pxToRem(40)};
  gap: ${pxToRem(32)};

  button {
    padding: ${pxToRem(8)} ${pxToRem(16)};
  }
`;
