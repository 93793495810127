import React, { useState, useEffect, DragEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoadIcon } from 'assets/load-image.svg';
import { useApi, Method } from 'service/api';
import { useParams } from 'react-router';
import { useFormContext, useWatch } from 'react-hook-form';
import { ImportFile, LastImagesBox, SuggestionImage, ImageList } from './styles';

export const ImagesSection = () => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();
  const selectedImage = useWatch({ name: 'pictureUrl' });

  const [displayMoreImages, setDisplayMoreImages] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);

  const prevImagesRef = useRef<string[]>();
  const prevImages: string[] = prevImagesRef.current || [];

  const { shopId }: { shopId: string } = useParams();

  const { data: images = [], sendRequest: refreshImages } = useApi<string[]>(
    [`shop/${shopId}/storage/files`],
    Method.GET
  );

  const {
    sendRequest,
    status: uploadStatus,
    resetResponse,
  } = useApi([`shop/${shopId}/storage/files`], Method.POST, true);

  useEffect(() => {
    if (files !== null) sendRequest(files[0]);
  }, [files]);

  const setSelectedImage = (image: string) => setValue('pictureUrl', image);

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    // triggers when file is dropped
    e.preventDefault();
    e.stopPropagation();

    setFiles(e.dataTransfer.files);
  };

  useEffect(() => {
    // store the previous images state
    prevImagesRef.current = images;

    // select most recently uploaded image
    if (prevImages !== undefined && prevImages.length > 0) {
      const addedImage = images.filter((x: string) => !prevImages.includes(x))[0];
      setSelectedImage(addedImage);
    }
  }, [images]);

  useEffect(() => {
    if (uploadStatus === 201) {
      refreshImages();
      setFiles(null);
      resetResponse();
    }
  }, [uploadStatus]);

  return (
    <>
      <ImportFile
        className="file-upload"
        htmlFor="image-file"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <input type="file" id="image-file" onChange={(e) => setFiles(e.target.files)} />
        <p>{t('dragImage')}</p>
      </ImportFile>

      <LastImagesBox>
        {t('selectImage')}
        <div>
          {images.slice(0, 3).map((image: string) => (
            <SuggestionImage
              key={image}
              type="button"
              className={selectedImage === image ? 'selected' : ''}
              onClick={() => setSelectedImage(image)}
              image={image}
            />
          ))}

          {images.length > 3 && (
            <button
              type="button"
              className={`load-img-btn ${displayMoreImages ? 'rotate' : ''}`}
              onClick={() => setDisplayMoreImages((prev) => !prev)}
            >
              <LoadIcon />
            </button>
          )}
        </div>
      </LastImagesBox>

      {displayMoreImages && (
        <ImageList>
          {images.slice(3, 100).map((image: string) => (
            <SuggestionImage
              key={image}
              type="button"
              className={selectedImage === image ? 'selected' : ''}
              onClick={() => setSelectedImage(image)}
              image={image}
            />
          ))}
        </ImageList>
      )}
    </>
  );
};
