import React, { HTMLProps, useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Summary, animationProps } from './styles';

type Props = {
  isOpenByDefault?: boolean;
  children: [JSX.Element, JSX.Element];
};

export const Accordion = ({ isOpenByDefault, children, ...rest }: Props) => {
  const restProps: HTMLProps<HTMLDivElement> = rest;

  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const ref = useRef<HTMLButtonElement>(null);

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const parentClassName = `${restProps?.className}${isOpen ? ' open' : ''}`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) setIsOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <div id="accordion" {...restProps} className={parentClassName}>
      <Summary onClick={toggleOpen} isOpen={isOpen} ref={ref}>
        {children[0]}
      </Summary>
      <AnimatePresence>
        {isOpen && (
          <motion.div key="content" {...animationProps}>
            {children[1]}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

Accordion.defaultProps = {
  isOpenByDefault: false,
};
