import { Column } from 'components/Table/content';
import { OrderedProduct } from 'utils/dto';
import { formatPrice } from 'utils/price';

export const HISTORY_PRODUCT_COLUMNS: Column<OrderedProduct>[] = [
  {
    header: 'product.name',
    accessor: 'name',
    transform: (product) => product.name,
  },
  {
    header: 'product.quantity',
    accessor: 'quantity',
    transform: (product) => product.quantity,
  },
  {
    header: 'product.price',
    accessor: 'price',
    transform: (product) => formatPrice(product.quantity * product.price),
  },
];
