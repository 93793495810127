import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as BurgerIcon } from 'assets/burgerIcon.svg';
import { FilterButton } from 'components/FilterButton';
import { AuthContext, Rights } from 'contexts/Auth';
import { ReactComponent as NotCurrentShopIcon } from 'assets/not-current-shop-icon.svg'; // Button
import { ReactComponent as AccordionArrow } from 'assets/accordion-arrow.svg';
import { ShopInformation } from 'utils/dto';
import {
  AccordionNavbar,
  HeaderBox,
  TitleContainer,
  Title,
  Accordion,
  CurrentShopIcon,
  AccordionSummary,
  AccordionDetails,
} from './styles';

export const Header = () => {
  const { t } = useTranslation();

  const { currentShop, resetAuthData, shopList, setCurrentShop } = useContext(AuthContext);
  const history = useHistory();
  const [isHeaderDisplayed, setIsHeaderDisplayed] = useState(true);
  const [currentPage, setCurrentPage] = useState('');

  const changeShop = (newShop: ShopInformation) => {
    setCurrentShop(newShop);
    history.push(`/backoffice/${currentShop?.id}`);
  };

  const location = useLocation();

  useEffect(() => setCurrentPage(location?.pathname?.split('/').pop() || ''), [location]);

  if (typeof window !== 'undefined') {
    let prevScrollpos = window.pageYOffset;

    window.onscroll = () => {
      const currentScrollPos = window.pageYOffset;

      setIsHeaderDisplayed(prevScrollpos > currentScrollPos);

      prevScrollpos = currentScrollPos;
    };
  }

  return (
    <HeaderBox isDisplayed={isHeaderDisplayed}>
      <AccordionNavbar>
        <AccordionSummary>
          <Title>MENU</Title>
          <BurgerIcon width="20" />
        </AccordionSummary>
        <AccordionDetails>
          {currentShop?.rights?.includes(Rights.Order) && [
            <a href="orders">{t('navbar.toOrders')}</a>,
            <a href="history" id="history-a">
              {t('navbar.toHistory')}
            </a>,
          ]}
          <a href="catalogue">{t('navbar.toCatalogue')}</a>
          <a href="categories">{t('navbar.toCategories')}</a>
        </AccordionDetails>
      </AccordionNavbar>
      <TitleContainer>
        <Title>{t('headerTitle')}</Title>
        {currentPage && <Title>{t(`subtitle.${currentPage}`)}</Title>}
      </TitleContainer>

      {location.pathname.includes('orders') && <FilterButton />}

      <Accordion>
        <AccordionSummary>
          <CurrentShopIcon />
          <span>{currentShop?.name}</span>
          <span>
            <AccordionArrow className="rotate--180" />
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {shopList
            .filter((shop) => shop !== currentShop)
            .map((shop) => (
              <button key={shop.id} onClick={() => changeShop(shop)} type="button">
                <NotCurrentShopIcon />
                {shop.name}
              </button>
            ))}
          <button type="button" className="disconnect" onClick={resetAuthData}>
            {t('disconnect')}
          </button>
        </AccordionDetails>
      </Accordion>
    </HeaderBox>
  );
};
