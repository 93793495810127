export enum Fonts {
  primary = '"Roboto", Numans, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Helvetica Neue", sans-serif',
  secondary = '"Nunito Sans", Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Helvetica Neue", sans-serif',
}

export enum FontWeight {
  xBold = 900,
  bold = 700,
  regular = 400,
  light = 300,
}

export enum FontSizes {
  title = '1.5rem', // texts in 24px on figma
  big = '1.25rem', // texts in 20px on figma
  medium = '1.125rem', // texts in 18px on figma
  regular = '1rem', // texts in 16px on figma
  small = '0.75rem', // texts in 12px on figma
}
