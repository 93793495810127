import { isYesterday, isToday, format } from 'date-fns';
import i18n from 'i18next';

export const calculateTimeToNow = (startTime: Date) => {
  const now = new Date();

  const dateInSecond = (date: Date) => Math.floor(date.getTime() / 1000);

  const differenceInSeconds = dateInSecond(now) - dateInSecond(startTime);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);

  const secondLeft = differenceInSeconds - differenceInMinutes * 60;

  const formatOutput = (number: number) => (number < 10 ? `0${number}` : number);

  return `${formatOutput(differenceInMinutes)}:${formatOutput(secondLeft)}`;
};

export const formatDay = (date: Date) => {
  if (isToday(date)) return i18n.t('today');
  if (isYesterday(date)) return i18n.t('yesterday');

  return format(date, 'P');
};

export const formatTime = (date: Date) => format(date, 'HH:mm');
