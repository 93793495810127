import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const ImportFile = styled.label`
  background: ${Colors.primaryLighter};
  cursor: pointer;

  height: ${pxToRem(80)};
  width: 100%;
  overflow: hidden;
  border-radius: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: ${pxToRem(16)};

  p {
    margin: 0 2rem;
  }

  input {
    display: none;
  }
`;

export const LastImagesBox = styled.div`
  color: rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${pxToRem(6)};
    gap: ${pxToRem(8)};
  }

  .load-img-btn {
    background: ${Colors.primary};
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    border-radius: 50%;
    margin-left: ${pxToRem(10)};

    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms ease-in-out;

    &.rotate {
      transform: rotate(90deg);
    }

    svg {
      margin-left: 5px;
    }
  }
`;

type SuggestionImageProps = { image: string };

export const SuggestionImage = styled.button<SuggestionImageProps>`
  all: unset;
  background: lightcoral;
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  border-radius: 50%;
  cursor: pointer;
  background-size: cover;
  background-image: ${(props) => `url(${props.image})`};
  box-sizing: border-box;

  &.selected {
    border: 4px solid ${Colors.secondary};
    transform: scale(1.1);
  }
`;

export const ImageList = styled.div`
  grid-column: span 2;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${pxToRem(8)};
`;
