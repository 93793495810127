import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const TOAST_TIMER_MS = 3000;
const NETWORK_ERROR_RESPONSE = 'NetworkError when attempting to fetch resource.';
const NETWORK_ERROR_MESSAGE = 'error.network';
const DEFAULT_TOAST_PARAMS = {
  autoClose: TOAST_TIMER_MS,
};

export interface ToasterContextType {
  displayError: (message: string) => void;
  displayWarning: (message: string) => void;
  displaySuccess: (message: string) => void;
}

export const defaultContextValue: ToasterContextType = {
  displayError: () => null,
  displayWarning: () => null,
  displaySuccess: () => null,
};

export const ToasterContext = createContext<ToasterContextType>(defaultContextValue);

type ToasterContextProviderProps = {
  children: JSX.Element;
};

export const ToasterContextProvider = (props: ToasterContextProviderProps) => {
  const { t } = useTranslation();

  const displayError = (message: string) => {
    // TEMP: to remove when we use webhooKs
    const networkError = message === NETWORK_ERROR_RESPONSE;

    if (networkError) {
      toast.error(t(NETWORK_ERROR_MESSAGE), DEFAULT_TOAST_PARAMS);
    } else {
      toast.error(message, {
        autoClose: false,
      });
    }
  };

  const displayWarning = (message: string) => {
    toast.warn(message, DEFAULT_TOAST_PARAMS);
  };

  const displaySuccess = (message: string) => {
    toast.success(message, DEFAULT_TOAST_PARAMS);
  };

  const ToasterContextValue: ToasterContextType = {
    displayError,
    displaySuccess,
    displayWarning,
  };

  return (
    <ToasterContext.Provider value={ToasterContextValue}>{props.children}</ToasterContext.Provider>
  );
};
