import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogueContext } from 'contexts/Catalogue';
import { CenteredModal, Direction } from 'components/CenteredModal';
import { CreateProductModal } from 'components/CreateProductModal';
import { Table } from 'components/Table';
import { Colors } from 'theme';
import { Cta } from 'theme/globals';

import { Page, OptionsBox } from './styles';
import { useProductsColumns } from './useProductsColumns';
import { DEFAULT_PRODUCT } from './content';

export const CataloguePage = () => {
  const { t } = useTranslation();

  const { refetchCatalogue, productInModal, setProductInModal, rows } =
    useContext(CatalogueContext);

  const columns = useProductsColumns();

  const [searchValue, setSearchValue] = useState('');
  const onAddProductClick = () => setProductInModal(DEFAULT_PRODUCT);

  useEffect(() => {
    refetchCatalogue();
  }, []);

  return (
    <>
      <Page>
        <OptionsBox>
          <Cta onClick={onAddProductClick} bg={Colors.secondary}>
            {t('addProduct')}
          </Cta>

          <input
            type="text"
            placeholder={t('search')}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </OptionsBox>

        <Table columns={columns} data={rows} filter={{ field: 'name', value: searchValue }} />
      </Page>

      <CenteredModal
        isModalOpen={!!productInModal}
        toggleModal={() => setProductInModal(undefined)}
        direction={Direction.Left}
        title={t('addProduct')}
      >
        <CreateProductModal />
      </CenteredModal>
    </>
  );
};
