import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';

export const ComponentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxToRem(16)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)};
  gap: ${pxToRem(24)};

  h1 {
    color: ${Colors.primary};
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.bold};
    font-size: ${FontSizes.regular};
    text-align: center;
  }

  input {
    background: linear-gradient(${Colors.primaryLighter}, ${Colors.primaryLighter}) center bottom
      10px / calc(100% - 50px) 2px no-repeat;
    border: 1px solid ${Colors.secondary};
    border-radius: 5px;
    width: 60%;
    height: ${pxToRem(50)};
    padding: 0 ${pxToRem(25)};
  }
`;

export const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${pxToRem(40)};
`;
