import styled from 'styled-components';
import { ReactComponent as NeedHelpIconSvg } from 'assets/NeedHelpIcon.svg'; // Sticker
import { ReactComponent as ServedIconSvg } from 'assets/ServedIcon.svg'; // Sticker
import { ReactComponent as WantToPayIconSvg } from 'assets/wantToPayIcon.svg'; // Sticker
import { Colors, shake } from 'theme';
import { centerChildren, pxToRem } from 'utils/style-utils';

export const ComponentBox = styled.div`
  margin: ${pxToRem(24)} 0;
  max-width: ${pxToRem(240)};
  position: relative;
`;

type CardProps = {
  isMuted?: boolean;
  isInForeground?: boolean;
};

export const Card = styled.div<CardProps>`
  background-color: ${Colors.lightBackground};
  border-radius: 10px 10px 0px 0px;
  padding-top: ${pxToRem(8)};
  height: ${(props) => (props.isMuted ? pxToRem(125) : 'auto')};
  width: 100%;
  overflow: hidden;

  filter: ${(props) => (props.isMuted ? 'brightness(50%)' : 'none')};

  position: ${(props) => (props.isInForeground ? 'absolute' : 'block')};
  top: ${pxToRem(125)};
`;

/* Stickers */

export const StickersBox = styled.div`
  display: flex;
  margin: -${pxToRem(20)} 0 0 ${pxToRem(24)};
  position: absolute;
  top: 0;
`;

type StickerProps = {
  isHelpType?: boolean;
  color?: string;
};

export const Sticker = styled.button<StickerProps>`
  background: ${(props) => {
    switch (props.color) {
      case 'error':
        return Colors.error;
      case 'needsPayment':
        return Colors.validate;
      default:
        return Colors.validate;
    }
  }};
  border-radius: 50%;
  margin-right: ${pxToRem(-8)};
  pointer-events: ${(props) => (props.isHelpType ? 'auto' : 'none')};
  height: ${pxToRem(40)};
  width: ${pxToRem(40)};

  animation: ${(props) => (props.isHelpType ? shake : '')} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    both infinite;

  ${centerChildren}
`;

export const NeedHelpIcon = styled(NeedHelpIconSvg)`
  height: ${pxToRem(21)};
  width: ${pxToRem(20)};
  fill: ${Colors.utilityBright};
`;

export const ServedIcon = styled(ServedIconSvg)`
  height: ${pxToRem(19)};
  width: ${pxToRem(24)};
  fill: ${Colors.utilityBright};
`;

export const NeedsPaymentIcon = styled(WantToPayIconSvg)`
  height: ${pxToRem(23)};
  width: ${pxToRem(24)};
  fill: ${Colors.utilityBright};
`;
