import React, { HTMLProps } from 'react';
import { useWatch } from 'react-hook-form';
import { Colors } from 'theme';
import { Category } from 'utils/dto';
import { useSuggestionField } from './hook';
import { Component, DeleteButton, SuggestionsBox } from './styles';

type Props = { categories: Category[] } & HTMLProps<HTMLInputElement>;

export const CategorySuggestionField = ({ categories, ...rest }: Props) => {
  const restProps: HTMLProps<HTMLInputElement> = rest;

  const { displaySuggestions, ref, setDisplaySuggestions, inputProps, formMethods } =
    useSuggestionField(true);

  const { control, setValue, register } = formMethods;

  const value: { id: string; name: string } = useWatch({
    control,
    name: 'category',
  });

  const onSuggestionClick = (suggestion: Category) => {
    setDisplaySuggestions(false);
    setValue('category', suggestion);
    setValue('categoryId', suggestion.id);
  };

  const suggestions = categories.filter((category) =>
    category.name.toLocaleLowerCase().includes((value.name || '').toLocaleLowerCase())
  );

  const onCancelButton = () => {
    setValue('category', { id: '', name: '' });
    setValue('categoryId', '');
  };

  return (
    <Component>
      <input {...inputProps} {...restProps} {...register('category.name', { required: true })} />
      {value && value.name && (
        <DeleteButton
          onClick={onCancelButton}
          type="button"
          backgroundColor={Colors.utilityDark}
          isSmall
        >
          X
        </DeleteButton>
      )}

      {displaySuggestions && suggestions.length > 0 && (
        <SuggestionsBox ref={ref}>
          {suggestions.map((suggestion) => (
            <button type="button" onClick={() => onSuggestionClick(suggestion)} key={suggestion.id}>
              {suggestion.name}
            </button>
          ))}
        </SuggestionsBox>
      )}
    </Component>
  );
};
