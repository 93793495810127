import React from 'react';
import { CancelCrossWrapper, CancelCrossIcon } from './styles';

type CancelCrossProps = { onClick: () => void };

export const CancelCross = ({ onClick }: CancelCrossProps) => (
  <CancelCrossWrapper onClick={onClick}>
    <CancelCrossIcon />
  </CancelCrossWrapper>
);
