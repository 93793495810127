import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const OptionBox = styled.div`
  background: ${Colors.utilityBright};
  display: flex;
  justify-content: space-between;
  grid-column: span 2;
  border-radius: 10px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  padding: ${pxToRem(16)};
`;

export const ColumnBox = styled.div`
  width: 100%;

  input:nth-child(1) {
    margin-bottom: ${pxToRem(12)};
  }
`;

export const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${pxToRem(30)};
  column-gap: ${pxToRem(40)};
`;

export const AddOption = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: ${pxToRem(8)};
`;
