import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

export enum IsServedStatus {
  All = 'All',
  IsNotServed = 'IsNotServed',
  IsServed = 'IsServed',
}
export interface FilterContextType {
  isServedFilter: IsServedStatus;
  setIsServedFilter: Dispatch<SetStateAction<IsServedStatus>>;
}

export const defaultContextValue: FilterContextType = {
  isServedFilter: IsServedStatus.All,
  setIsServedFilter: () => null,
};

export const FilterContext = createContext<FilterContextType>(defaultContextValue);

type FilterContextProviderProps = {
  children: JSX.Element;
};

export const FilterContextProvider = (props: FilterContextProviderProps) => {
  const [isServedFilter, setIsServedFilter] = useState(defaultContextValue.isServedFilter);

  const FilterContextValue: FilterContextType = {
    isServedFilter,
    setIsServedFilter,
  };

  return (
    <FilterContext.Provider value={FilterContextValue}>{props.children}</FilterContext.Provider>
  );
};
