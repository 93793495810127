import { OrderedProductToEdit } from 'components/OrderModals/content';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { Order, OrderedProduct } from 'utils/dto';

export enum ModalType {
  None,
  Cancel,
  Summary,
  Invoice,
  SplitBill,
  EditionValidation,
  SelectProduct,
}

type ContextType = {
  relevantOrder: Order | null;
  setRelevantOrder: Dispatch<SetStateAction<Order | null>>;
  relevantOrderUnMerged: Order | null;
  setRelevantOrderUnMerged: Dispatch<SetStateAction<Order | null>>;
  displayedModal: ModalType;
  toggleModal: (modal: ModalType) => void;
  closeModal: () => void;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  productsToEdit: OrderedProductToEdit[];
  setProductsToEdit: Dispatch<SetStateAction<OrderedProductToEdit[]>>;
  selectedProducts: OrderedProduct[];
  setSelectedProducts: Dispatch<SetStateAction<OrderedProduct[]>>;
  productsToPayPrice: number;
  setProductsToPayPrice: Dispatch<SetStateAction<number>>;
  clearSelectedProduct: () => void;
};

const defaultValue: ContextType = {
  relevantOrder: null,
  setRelevantOrder: () => null,
  relevantOrderUnMerged: null,
  setRelevantOrderUnMerged: () => null,
  displayedModal: ModalType.None,
  closeModal: () => null,
  toggleModal: () => null,
  isEditMode: false,
  setIsEditMode: () => null,
  productsToEdit: [],
  setProductsToEdit: () => null,
  selectedProducts: [],
  setSelectedProducts: () => null,
  productsToPayPrice: 0,
  setProductsToPayPrice: () => null,
  clearSelectedProduct: () => null,
};

export const OrderModalContext = createContext<ContextType>(defaultValue);

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [relevantOrder, setRelevantOrder] = useState<Order | null>(defaultValue.relevantOrder);
  const [relevantOrderUnMerged, setRelevantOrderUnMerged] = useState<Order | null>(
    defaultValue.relevantOrderUnMerged
  );
  const [displayedModal, setDisplayedModal] = useState(defaultValue.displayedModal);
  const [isEditMode, setIsEditMode] = useState(defaultValue.isEditMode);
  const [productsToEdit, setProductsToEdit] = useState(defaultValue.productsToEdit);
  const [selectedProducts, setSelectedProducts] = useState(defaultValue.selectedProducts);
  const [productsToPayPrice, setProductsToPayPrice] = useState(defaultValue.productsToPayPrice);

  const closeModal = () => setDisplayedModal(ModalType.None);

  const toggleModal = useCallback(
    (modal: ModalType) => (displayedModal === modal ? closeModal() : setDisplayedModal(modal)),
    [displayedModal]
  );

  const clearSelectedProduct = () => {
    setSelectedProducts(defaultValue.selectedProducts);
    setProductsToPayPrice(defaultValue.productsToPayPrice);
  };

  useEffect(() => {
    if (displayedModal === ModalType.None) {
      setRelevantOrder(defaultValue.relevantOrder);
      setIsEditMode(defaultValue.isEditMode);
      setProductsToEdit([]);
    }
  }, [displayedModal]);

  const value: ContextType = {
    relevantOrder,
    setRelevantOrder,
    relevantOrderUnMerged,
    setRelevantOrderUnMerged,
    displayedModal,
    closeModal,
    toggleModal,
    isEditMode,
    setIsEditMode,
    productsToEdit,
    setProductsToEdit,
    selectedProducts,
    setSelectedProducts,
    productsToPayPrice,
    setProductsToPayPrice,
    clearSelectedProduct,
  };

  return <OrderModalContext.Provider value={value}>{children}</OrderModalContext.Provider>;
};
