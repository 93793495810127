import styled, { css } from 'styled-components';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';

type ProgressBarProps = {
  progression: string;
};

export const ProgressBar = styled.div<ProgressBarProps>`
  background: ${Colors.primaryLighter};
  height: ${pxToRem(8)};
  margin-top: 1rem;
  width: 100%;
  margin-bottom: ${pxToRem(20)};
  position: relative;

  ::after {
    content: '';
    background: ${Colors.primary};
    position: absolute;
    height: 100%;
    width: ${(props) => props.progression};
    transition: width 200ms ease-in-out;
  }
`;

export const PaymentDataBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  margin-top: ${pxToRem(16)};

  p {
    color: ${Colors.font};
    font-family: ${Fonts.primary};
  }

  div {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    gap: ${pxToRem(32)};

    &:last-of-type {
      p {
        font-weight: ${FontWeight.bold};
      }
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${Colors.secondary};
`;

export const ValidateButton = styled.button`
  background: ${Colors.primary};
  border-radius: ${pxToRem(5)};

  width: calc(100% - ${pxToRem(100)});
  margin: ${pxToRem(24)} 0 0;
  padding: ${pxToRem(18)};

  color: ${Colors.utilityBright};
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.big};
  font-weight: ${FontWeight.bold};
  text-align: center;

  &.secondary {
    background: transparent;
    color: ${Colors.primary};
    border: 4px solid ${Colors.primary};
  }
`;

export const modalContentBox = css`
  margin: ${pxToRem(20)} ${pxToRem(25)};
  padding: 0;
  max-width: ${pxToRem(400)};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
