/* eslint-disable @typescript-eslint/no-explicit-any */
import { IsServedStatus } from 'contexts/Filter';
import { Order, OrderedProduct } from './dto';
import { mergeProducts } from './product';

type Acc = {
  [key: string]: Order[];
};

export const groupByTableNumber = (array: Order[]) => {
  const groups = array.reduce((accumulator: Acc, currentOrder) => {
    const currentTableNumber = currentOrder.tableNumber.toString(10);

    const alreadyInAcc = Object.prototype.hasOwnProperty.call(accumulator, currentTableNumber);

    if (!alreadyInAcc) {
      accumulator[currentTableNumber] = [];
    }

    accumulator[currentTableNumber].push(currentOrder);

    return accumulator;
  }, {});

  return Object.values(groups);
};

export const getMostRecentOrder = (orders: Order[]) => {
  if (orders.length <= 0) throw new Error('Orders should contain at least 1 Order');

  const mostRecentDate = new Date(Math.max(...orders.map((o) => new Date(o.createdAt).getTime())));

  const mostRecentOrder = orders.find(
    (order) => order.createdAt.getTime() === mostRecentDate.getTime()
  );

  if (!mostRecentOrder) throw new Error('orders.find must return at least 1 order');

  return mostRecentOrder;
};

export const getOrdersByServed = (orders: Order[], status: IsServedStatus) =>
  orders.filter((order) => {
    if (status === IsServedStatus.IsNotServed) return order.isServed === false;
    if (status === IsServedStatus.IsServed) return order.isServed === true;

    return true;
  });

export const sortOrderContent = (orders: Order[]) => {
  const sortById = (a: any, b: any) => {
    if (a.productId) return a.productId > b.productId ? 1 : -1;
    if (a.id) return a.id > b.id ? 1 : -1;

    return 0;
  };

  orders.sort(sortById);

  orders.forEach((order) => {
    order.orderedProducts.forEach((op) => {
      op.orderedOptions.sort(sortById);
    });
    order.orderedProducts.sort(sortById);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiOrdersToOrder = (apiOrders: any, needMerge: boolean): Order[] =>
  // TODO: add try catch which throw error if apiOrder couldn't be converted to Order
  apiOrders.map((apiOrder: Order) => {
    const products: OrderedProduct[] = apiOrder.orderedProducts.map((orderedProduct) => ({
      ...orderedProduct,
      quantity: 1,
    }));

    const mergedProducts = mergeProducts(products);
    const order: Order = {
      ...apiOrder,
      createdAt: new Date(apiOrder.createdAt),
      orderedProducts: needMerge ? mergedProducts : products,
      specificInstructions: apiOrder.specificInstructions?.trim(),
    };

    return order;
  });

export const getNeedHelpList = (orders: Order[]) => {
  return orders.reduce((acc: number[], curr: Order) => {
    if (curr.needsHelp) acc.push(curr.tableNumber);
    return acc;
  }, []);
};
