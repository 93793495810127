import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandedHistoryOrder } from 'components/ExpandedHistoryOrder';
import { Order } from 'utils/dto';

import { Column, getSortingValue, isExtended, sortDataFunction, SortType } from './content';
import { Component, SortArrow, NoRows, ExpendArrow } from './styles';

type Props<T extends object & { id: string }> = {
  columns: Column<T>[];
  data: T[];
  filter?: { field: keyof T; value: string };
  onRowClick?: (row: T) => void;
  hasChildRows?: boolean;
};

export const Table = <T extends object & { id: string }>({
  columns,
  data = [],
  filter,
  onRowClick = () => null,
  hasChildRows,
}: Props<T>) => {
  const [sortBy, setSortBy] = useState<SortType<T>>();
  const [expandedRows, setExpandedRows] = useState<T[]>([]);

  const { t } = useTranslation();

  const onHeaderCellClick = (columnKey?: keyof T) => {
    if (!columnKey) return;

    setSortBy(getSortingValue(columnKey, sortBy));
  };

  const rowsToDisplay = data
    .sort((a, b) => sortDataFunction<T>(a, b, sortBy))
    .filter((e) => {
      if (!filter) return true;

      const field = e[filter.field];

      return (
        typeof field === 'string' &&
        field.toLocaleLowerCase().includes(filter?.value.toLocaleLowerCase())
      );
    });

  const handleRowClick = (row: T) => {
    // fold/unfold row
    if (hasChildRows) {
      if (isExtended(expandedRows, row))
        return setExpandedRows((prev) => prev.filter((e) => e.id !== row.id));

      return setExpandedRows((prev) => [...prev, row]);
    }

    return onRowClick(row);
  };

  return (
    <Component>
      <thead>
        <tr>
          {hasChildRows && <th> </th>}

          {columns.map((col, index) => (
            <th key={index} onClick={() => onHeaderCellClick(col.accessor)}>
              {t(col.header)}
              {col.accessor && sortBy?.key === col.accessor && (
                <SortArrow sortOrder={sortBy?.order} />
              )}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rowsToDisplay.map((row) => (
          <React.Fragment key={row.id}>
            <tr onClick={() => handleRowClick(row)}>
              {hasChildRows && (
                <td>
                  <ExpendArrow isRotated={isExtended(expandedRows, row)} />
                </td>
              )}

              {columns.map((col, index) => (
                <td key={index}>{col.transform(row)}</td>
              ))}
            </tr>

            {hasChildRows && isExtended(expandedRows, row) && (
              <ExpandedHistoryOrder order={row as unknown as Order} />
            )}
          </React.Fragment>
        ))}

        {data.length < 1 && (
          <NoRows>
            <td colSpan={columns.length}>La liste est vide</td>
          </NoRows>
        )}
      </tbody>
    </Component>
  );
};

Table.defaultProps = {
  onRowClick: () => null,
  filter: undefined,
  hasChildRows: false,
};
