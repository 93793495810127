import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelOrderModal } from 'components/OrderModals/CancelOrderModal';
import { FilterContext } from 'contexts/Filter';
import { OrdersContext } from 'contexts/Orders';
import { ModalContextProvider } from 'contexts/OrderModal';
import { OrderSummaryModal } from 'components/OrderModals/OrderSummaryModal/index';
import { OrderTicket } from 'components/OrderTicket';
import { getOrdersByServed } from 'utils/order';
import { splitIntoColumns } from 'utils/utils';
import { InvoiceModal } from 'components/OrderModals/InvoiceModal';
import { SplitBillModal } from 'components/OrderModals/SplitBillModal';
import { EditValidationModal } from 'components/OrderModals/EditValidationModal';
import { SelectedProductModal } from 'components/OrderModals/SelectedProductModal';
import { Page, ErrorBox } from './styles';

export const OrdersPage = () => {
  const { isServedFilter } = useContext(FilterContext);
  const { orders, error } = useContext(OrdersContext);

  const { t } = useTranslation();

  const filteredOrders = getOrdersByServed(orders, isServedFilter);

  const ordersInColumns = splitIntoColumns(filteredOrders);

  return (
    <ModalContextProvider>
      <>
        {error ? (
          <ErrorBox>{t('errorOccurred')}</ErrorBox>
        ) : (
          <Page>
            {ordersInColumns.map((column, index) => (
              <div key={index}>
                {column.map((order) => (
                  <OrderTicket key={order.tableNumber} order={order} />
                ))}
              </div>
            ))}
          </Page>
        )}

        <CancelOrderModal />
        <InvoiceModal />
        <SplitBillModal />
        <OrderSummaryModal />
        <EditValidationModal />
        <SelectedProductModal />
      </>
    </ModalContextProvider>
  );
};
