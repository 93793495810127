import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateProduct } from 'utils/api-dto';
import { Component } from './styles';

type Props = { name: keyof CreateProduct };

export const Switch = ({ name }: Props) => {
  const { register } = useFormContext();

  return (
    <Component>
      <input type="checkbox" {...register(name)} />
      <span className="slider round" />
    </Component>
  );
};
