import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router';
import { useApi, Method } from 'service/api';
import { Category } from 'utils/dto';

export const EMPTY_CATEGORY: Category = {
  id: '',
  name: '',
  boOrder: -1,
  frontOrder: -1,
  shopId: '',
  nbProducts: -1,
};

export type CategoriesContextType = {
  categories: Category[];
  setCategories: Dispatch<SetStateAction<Category[]>>;
  displayModal: boolean;
  setDisplayModal: Dispatch<SetStateAction<boolean>>;
  categoryToEdit: Category;
  setCategoryToEdit: Dispatch<SetStateAction<Category>>;
  refreshCategories: () => void;
};

const defaultValue: CategoriesContextType = {
  categories: [],
  setCategories: () => null,
  categoryToEdit: EMPTY_CATEGORY,
  displayModal: false,
  setDisplayModal: () => null,
  setCategoryToEdit: () => null,
  refreshCategories: () => null,
};

export const CategoriesContext = createContext<CategoriesContextType>(defaultValue);

export const CategoriesContextProvider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState(defaultValue.categories);
  const [displayModal, setDisplayModal] = useState(defaultValue.displayModal);
  const [categoryToEdit, setCategoryToEdit] = useState<Category>(defaultValue.categoryToEdit);

  const { shopId }: { shopId: string } = useParams();

  const { data, sendRequest: refreshCategories } = useApi<Category[]>(
    [`shop/${shopId}/categories`],
    Method.GET
  );

  useEffect(() => {
    setCategories(data);
  }, [data]);

  const value: CategoriesContextType = {
    categories,
    setCategories,
    categoryToEdit,
    setCategoryToEdit,
    displayModal,
    setDisplayModal,
    refreshCategories,
  };

  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};
