import React from 'react';
import styled from 'styled-components';
import { Colors, FontSizes, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { ReactComponent as QuoteSvg } from 'assets/quotation-icon.svg';

type Props = {
  text: string;
  isComment?: boolean;
};

export const Instructions = ({ text, isComment }: Props) => {
  return (
    <InstructionsBox isComment={isComment}>
      <QuoteSvg />
      {text}
    </InstructionsBox>
  );
};

Instructions.defaultProps = {
  isComment: false,
};

const InstructionsBox = styled.div<{ isComment?: boolean }>`
  position: relative;
  background: ${Colors.primaryLighter};
  padding: ${(props) => pxToRem(props.isComment ? 8 : 16)} ${pxToRem(16)};

  color: ${Colors.utilityDark};
  font-style: italic;
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.bold};

  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;

  svg {
    min-width: ${pxToRem(16)};

    path {
      fill: ${Colors.secondary};
    }
  }

  &::after {
    content: ${(props) => (props.isComment ? "''" : 'none')};
    position: absolute;
    top: -${pxToRem(20)};
    left: ${pxToRem(24)};
    margin-left: -${pxToRem(10)};
    border-width: ${pxToRem(10)};
    border-style: solid;
    border-color: transparent transparent ${Colors.primaryLighter} transparent;
  }
`;
