import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/price';
import { ButtonWrapper, Button } from './styles';

export const Buttons = () => {
  const {
    toggleModal,
    isEditMode,
    setIsEditMode,
    relevantOrder,
    setProductsToEdit,
    productsToEdit,
  } = useContext(OrderModalContext);

  const { t } = useTranslation();

  if (!relevantOrder) return null;

  const displayPaymentMethods = Cookies.get('DISPLAY_PAYMENT_METHODS');

  const onEditClick = () => {
    toggleModal(ModalType.EditionValidation);
  };

  const handleCancelClick = () => {
    // FIXME: useEffect this in ctx
    setIsEditMode(false);
    setProductsToEdit([]);
  };

  return (
    <ButtonWrapper>
      {displayPaymentMethods && (
        <>
          <Button disabled={isEditMode} onClick={() => toggleModal(ModalType.SplitBill)}>
            {t('splitBill')}
          </Button>

          <Button disabled={isEditMode} onClick={() => toggleModal(ModalType.SelectProduct)}>
            {t('selectProductsToPay')}
          </Button>

          <Button disabled={isEditMode} onClick={() => toggleModal(ModalType.Invoice)}>
            {t('arbitraryPayment')}
          </Button>
        </>
      )}

      <Button
        onClick={() => toggleModal(ModalType.Invoice)}
        disabled={isEditMode || relevantOrder.isPaid}
        fullWidth={!displayPaymentMethods}
        isEmpty={false}
      >
        {t('payAll')}
        <span>{formatPrice(relevantOrder.leftToPay)}</span>
      </Button>

      {isEditMode ? (
        <>
          <Button onClick={handleCancelClick} isEmpty>
            {t('cancelEdition')}
          </Button>

          <Button onClick={onEditClick} disabled={productsToEdit.length <= 0}>
            {t('validateEdition')}
          </Button>
        </>
      ) : (
        <Button onClick={() => setIsEditMode(true)} isEmpty fullWidth>
          {t('modifyOrder')}
        </Button>
      )}
    </ButtonWrapper>
  );
};
