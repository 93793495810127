import styled from 'styled-components';
import { Colors } from 'theme';
import { CircularCta } from 'theme/globals';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.div`
  position: relative;
`;

export const SuggestionsBox = styled.div`
  background: ${Colors.lightBackground};
  position: absolute;
  top: 100%;
  right: 0;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  padding: ${pxToRem(13)} ${pxToRem(16)};
  z-index: 1;
  max-width: ${pxToRem(170)};

  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};

  max-height: ${pxToRem(300)};
  overflow-y: scroll;

  button {
    width: 100%;
  }
`;

export const DeleteButton = styled(CircularCta)`
  opacity: 0.5;
  position: absolute;
  top: ${pxToRem(4)};
  right: ${pxToRem(8)};
`;
