import styled from 'styled-components';
import { Colors, Fonts } from 'theme';
import { ReactComponent as DotsSVG } from 'assets/table-horizontal-dots.svg'; // Button
import { pxToRem } from 'utils/style-utils';

export const Page = styled.div`
  padding: 1.5rem 5rem;
  background-color: ${Colors.darkBackground};
  font-family: ${Fonts.primary};
`;

export const OptionsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${pxToRem(40)};

  input {
    border-color: ${Colors.utilityDark};
    border-radius: 3px;
    background: ${Colors.lightBackground};
    margin-left: auto;
    padding: 0.6rem 0.8rem;
  }

  button {
    width: auto;
  }
`;

export const EditDots = styled(DotsSVG)`
  padding: ${pxToRem(7)};

  ellipse {
    fill: ${Colors.primary};
  }
`;

export const PriceCell = styled.span`
  white-space: nowrap;
`;
