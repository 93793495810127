import React from 'react';
import { OrderedProduct } from 'utils/dto';
import { Instructions } from '../Instructions';
import { ProductWrapper, Product, Quantity, OptionBox, Option } from './styles';

type ProductListProps = {
  orderedProducts: OrderedProduct[];
};

export const ProductList = ({ orderedProducts }: ProductListProps) => {
  return (
    <div>
      {orderedProducts.map((product, index) => {
        const isNextProductCategoryDifferent =
          product.category !== orderedProducts[index + 1]?.category;

        return (
          <ProductWrapper key={product.id} bottomBorder={isNextProductCategoryDifferent}>
            <Product>
              {product.quantity > 1 && <Quantity>(x{product.quantity})</Quantity>}
              {product.name}
              {!!product.orderedOptions?.length && (
                <OptionBox>
                  {product.orderedOptions.map((option) => (
                    <Option key={option.id}>{option.name}</Option>
                  ))}
                </OptionBox>
              )}
            </Product>

            {product.comment && <Instructions text={product.comment} isComment />}
          </ProductWrapper>
        );
      })}
    </div>
  );
};
