import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoriesSortableList } from 'components/CategoriesSortableList';
import { EditCategoryModal } from 'components/EditCategoryModal';
import { CategoriesContext } from 'contexts/Categories';
import { Colors } from 'theme';
import { CircularCta } from 'theme/globals';

import { Component, AddCategory, Row, DivWithSeparator } from './styles';

export const CategoriesPage = () => {
  const { t } = useTranslation();

  const { setDisplayModal } = useContext(CategoriesContext);

  const handleCreate = () => setDisplayModal(true);

  return (
    <>
      <Component>
        <AddCategory onClick={handleCreate}>
          {t('categories.create')}
          <CircularCta backgroundColor={Colors.secondary}>+</CircularCta>
        </AddCategory>

        <Row>
          <DivWithSeparator>
            <h2>{t('categories.backOfficeOrder')}</h2>
            <CategoriesSortableList sortBy="boOrder" />
          </DivWithSeparator>

          <div>
            <h2>{t('categories.frontedOrder')}</h2>
            <CategoriesSortableList sortBy="frontOrder" />
          </div>
        </Row>
      </Component>

      <EditCategoryModal />
    </>
  );
};
