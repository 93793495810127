import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router';
import { Method, useApi } from 'service/api';
import { CreateProduct } from 'utils/api-dto';
import { Product } from 'utils/dto';

type ContextType = {
  rows: Product[];
  refetchCatalogue: () => void;
  productInModal: CreateProduct | Product | undefined;
  setProductInModal: Dispatch<SetStateAction<CreateProduct | Product | undefined>>;
};

const defaultValue: ContextType = {
  rows: [],
  refetchCatalogue: () => null,
  productInModal: undefined,
  setProductInModal: () => null,
};

export const CatalogueContext = createContext<ContextType>(defaultValue);

export const CatalogueContextProvider = ({ children }: { children: ReactNode }) => {
  const [rows, setRows] = useState(defaultValue.rows);
  const [productInModal, setProductInModal] = useState<CreateProduct | Product | undefined>(
    defaultValue.productInModal
  );

  const { shopId }: { shopId: string } = useParams();

  const { sendRequest: refetchCatalogue, data } = useApi<Product[]>(
    [`shop/${shopId}/products`],
    Method.GET
  );

  useEffect(() => {
    setRows(data);
  }, [data]);

  const value: ContextType = {
    rows,
    refetchCatalogue,
    productInModal,
    setProductInModal,
  };

  return <CatalogueContext.Provider value={value}>{children}</CatalogueContext.Provider>;
};
