import styled from 'styled-components';
import { Colors } from 'theme';
import arrow from 'assets/dropdown-arrow.svg';

export const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select<{ isSuccess: boolean }>`
  all: unset;
  padding: 0.5rem 2rem 0.5rem 0.8rem;
  border-radius: 5px;
  background-color: ${(props) => (props.isSuccess ? Colors.validate : Colors.error)};
  color: ${Colors.utilityBright};

  background-image: url(${arrow});

  background-position: right 1rem bottom 0.85rem;
  background-repeat: no-repeat;
`;
