import React, { useContext } from 'react';
import { CatalogueContext } from 'contexts/Catalogue';
import { Product } from 'utils/dto';
import { formatPrice } from 'utils/price';
import { Column } from 'components/Table/content';
import { AvailabilityDropDown } from 'components/AvailabilityDropDown';
import { EditDots, PriceCell } from './styles';

export const useProductsColumns = () => {
  const { setProductInModal } = useContext(CatalogueContext);

  const columns: Column<Product>[] = [
    {
      header: 'product.name',
      accessor: 'name',
      transform: (product) => product.name,
    },
    {
      header: 'product.category',
      accessor: 'category',
      transform: (product) => product.category.name,
    },
    {
      header: 'product.subCategory',
      accessor: 'subcategory',
      transform: (product) => product.subcategory,
    },
    {
      header: 'product.price',
      accessor: 'price',
      transform: (product) => <PriceCell>{formatPrice(product.price)}</PriceCell>,
    },
    {
      header: 'product.availability',
      accessor: 'isAvailable',
      transform: (product) => <AvailabilityDropDown product={product} />,
    },
    {
      header: '',
      transform: (product) => (
        <button type="button" onClick={() => setProductInModal(product)}>
          <EditDots />
        </button>
      ),
    },
  ];

  return columns;
};
