import React, { useContext } from 'react';
import { OrderModalContext } from 'contexts/OrderModal';
import { Modal, Paper } from '@mui/material';
import { ModalStyles, Slide, HeaderBox, HeaderTitle, getBodyStyle, CloseCrossIcon } from './styles';

export enum Direction {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down',
}

type CenteredModalProps = {
  children: JSX.Element;
  isModalOpen: boolean;
  toggleModal: () => void;
  direction?: Direction;
  title: string;
  noPadding?: boolean;
  isSummaryModal?: boolean;
};

export const CenteredModal = (props: CenteredModalProps) => {
  const { children, isModalOpen, toggleModal, direction, title, noPadding, isSummaryModal } = props;
  const { relevantOrder: order } = useContext(OrderModalContext);

  return (
    <Modal open={isModalOpen} onClose={toggleModal} style={ModalStyles}>
      <Slide direction={direction} in={isModalOpen}>
        <Paper>
          <HeaderBox>
            <HeaderTitle>{title}</HeaderTitle>
            {isSummaryModal && (
              <div>
                Table <b>{order?.tableNumber}</b>
              </div>
            )}

            <button type="button" onClick={toggleModal}>
              <CloseCrossIcon />
            </button>
          </HeaderBox>
          <div style={getBodyStyle(noPadding)}>{children}</div>
        </Paper>
      </Slide>
    </Modal>
  );
};

CenteredModal.defaultProps = {
  direction: Direction.Up,
  noPadding: false,
  isSummaryModal: false,
};
