import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApi, Method } from 'service/api';
import { LoadingWheel } from 'components/LoadingWheel';
import { AuthContext } from 'contexts/Auth';
import { AuthData } from 'utils/api-dto';
import { FormGrid, TextField, Logo, ConnectButton, ForgetPass, LoginContainer } from './styles';

const supportEmail = 'contact@iomentum.com';

type LoginForm = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthData, currentShop } = useContext(AuthContext);
  const { register, handleSubmit } = useForm<LoginForm>();

  const { data, sendRequest, isLoading, status, resetResponse, error } = useApi<AuthData>(
    ['login'],
    Method.POST
  );

  const onSubmit = (formData: LoginForm) => {
    resetResponse();
    sendRequest(formData);
  };

  useEffect(() => {
    if (status === 202) {
      if (data.shopsInformation.length === 0) return;

      setAuthData(data as AuthData);
    }
  }, [status]);

  useEffect(() => {
    if (currentShop) history.push(`/backoffice/${currentShop.id}`);
  }, [currentShop]);

  return (
    <>
      <LoginContainer onSubmit={handleSubmit(onSubmit)}>
        <Logo />
        <FormGrid>
          <TextField
            data-cy="login-input"
            {...register('email', { required: true })}
            label={t('email')}
            variant="standard"
          />
          <TextField
            data-cy="password-input"
            {...register('password', { required: true })}
            label={t('password')}
            type="password"
            variant="standard"
          />
        </FormGrid>
        <ForgetPass href={`mailto:${supportEmail}`}>{t('passwordForgotten')}</ForgetPass>
        <ConnectButton id="connection-button" type="submit" disabled={isLoading}>
          {isLoading ? <LoadingWheel diameter={16} /> : t('connect')}
        </ConnectButton>
        {error && <i>{t('errorOccurred')}</i>}
        {data?.shopsInformation?.length === 0 && <i>{t('anyShopError')}</i>}
      </LoginContainer>

      <Helmet>
        <title>{`IO'RDER - ${t('login')}`}</title>
      </Helmet>
    </>
  );
};
