import styled from 'styled-components';
import { headerHeight } from 'components/Header/styles';
import { Colors } from 'theme';

export const FullPage = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
`;

export const ContentBox = styled.div`
  display: flex;
  height: 100%;
`;

type Props = {
  isDarkBg: boolean;
};

export const Sections = styled.div<Props>`
  padding-top: ${headerHeight};
  height: calc(100% - ${headerHeight});
  width: 100%;
  background-color: ${(props) => (props.isDarkBg ? Colors.darkBackground : Colors.lightBackground)};
  overflow: scroll;
`;
