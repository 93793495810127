import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Page = styled.div`
  padding: 0 ${pxToRem(12)};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${pxToRem(16)};
`;

export const ErrorBox = styled.div`
  margin-top: 10%;
  color: ${Colors.error};
  text-align: center;
`;
