import styled from 'styled-components';
import { pxToRem } from 'utils/style-utils';
import { Colors, FontSizes, FontWeight } from 'theme';
import { Cta } from 'theme/globals';

export const BodyBox = styled.div`
  margin-top: ${pxToRem(8)};
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${pxToRem(24)} ${pxToRem(32)};
  margin-top: ${pxToRem(32)};
`;

export const Button = styled(Cta)<{ fullWidth?: boolean }>`
  gap: 0 2rem;
  flex-wrap: wrap;
  font-size: ${FontSizes.big};

  ${(props) =>
    props.fullWidth &&
    `
    grid-column: span 2;
  `}

  span {
    color: ${Colors.utilityBright};
    white-space: nowrap;
    font-weight: ${FontWeight.light};
  }
`;
