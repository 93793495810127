import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Redirect, useParams, useHistory, useLocation } from 'react-router-dom';

import { Header } from 'components/Header';
import { FilterContextProvider } from 'contexts/Filter';
import { OrdersContextProvider } from 'contexts/Orders';
import { CatalogueContextProvider } from 'contexts/Catalogue';
import { OrdersPage } from 'pages/OrdersPage';
import { HistoryPage } from 'pages/HistoryPage';
import { CataloguePage } from 'pages/CataloguePage';
import { CategoriesPage } from 'pages/CategoriesPage';
import { AuthContext, Rights } from 'contexts/Auth';
import { ToasterContextProvider } from 'contexts/Toaster';
import { CategoriesContextProvider } from 'contexts/Categories';
import { FullPage, ContentBox, Sections } from './styles';

export const BackofficeLayout = () => {
  const { shopId }: { shopId: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { token, currentShop } = useContext(AuthContext);

  useEffect(() => {
    // TODO: API-TOKEN - !isValidSession && logout
    if (!token) history.push(`/login`);
  }, [token]);

  const isDarkBg = !location.pathname.includes('catalogue');

  // FIXME: use context only in relevant page/s

  return (
    <>
      <FullPage>
        <ContentBox>
          <ToasterContextProvider>
            <FilterContextProvider>
              <CatalogueContextProvider>
                <CategoriesContextProvider>
                  {/* When adding routes, last url item must be in i18n for the header "subtitle" */}
                  <>
                    <Sections isDarkBg={isDarkBg}>
                      <Route exact path="/backoffice/:shopId">
                        {currentShop?.rights?.includes(Rights.Order) ? (
                          <Redirect to={`/backoffice/${shopId}/orders`} />
                        ) : (
                          <Redirect to={`/backoffice/${shopId}/catalogue`} />
                        )}
                      </Route>
                      <Route path="/backoffice/:shopId/orders">
                        <OrdersContextProvider>
                          <OrdersPage />
                        </OrdersContextProvider>
                      </Route>
                      <Route path="/backoffice/:shopId/history" component={HistoryPage} />
                      <Route path="/backoffice/:shopId/catalogue" component={CataloguePage} />
                      <Route path="/backoffice/:shopId/categories" component={CategoriesPage} />
                    </Sections>
                    <Header />
                  </>
                </CategoriesContextProvider>
              </CatalogueContextProvider>
            </FilterContextProvider>
          </ToasterContextProvider>
        </ContentBox>
      </FullPage>

      <Helmet>
        <title>{`${currentShop?.name} Backoffice - IO'RDER`}</title>
      </Helmet>
    </>
  );
};
