import styled from 'styled-components';
import { TextField as MuiTextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Colors, Fonts, FontSizes } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { ReactComponent as LogoSvg } from 'assets/IorderLogo.svg';

export const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  * {
    font-family: ${Fonts.primary};
  }

  i {
    margin-top: ${pxToRem(16)};
    color: ${Colors.error};
  }
`;

export const FormGrid = styled.div`
  display: grid;
  row-gap: ${pxToRem(32)};
  margin: ${pxToRem(56)} 0 ${pxToRem(16)} 0;
  padding: ${pxToRem(32)} ${pxToRem(50)};
  border: 2px solid ${Colors.utilityDark};
  border-radius: ${pxToRem(24)};
`;

export const TextField = withStyles({
  root: {
    width: pxToRem(200),
    borderBottom: `2px solid ${Colors.primaryLighter}`,
  },
})(MuiTextField);

export const Logo = styled(LogoSvg)`
  width: ${pxToRem(300)};
  height: auto;
`;

export const ForgetPass = styled.a`
  margin-bottom: ${pxToRem(40)};
  font-size: ${FontSizes.small};
  color: ${Colors.fontLighter};
  text-decoration: none;
`;

export const ConnectButton = styled.button`
  background-color: ${Colors.primary};
  color: ${Colors.utilityBright};
  text-align: center;
  padding: ${pxToRem(16)} ${pxToRem(50)};
  border-radius: ${pxToRem(8)};
  font-size: ${FontSizes.big};
`;
