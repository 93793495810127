import { Accordion as AccordionComponent } from 'components/Accordion';
import styled from 'styled-components';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';
import { pxToRem, withOpacity } from 'utils/style-utils';
import { ReactComponent as CurrentShopSvg } from 'assets/current-shop-icon.svg'; // Button

export const headerHeight = pxToRem(64);

type componentProps = {
  isDisplayed?: boolean;
};

export const HeaderBox = styled.div<componentProps>`
  position: fixed;
  width: 100%;
  height: ${headerHeight};
  top: ${(props) => (props.isDisplayed ? '0' : `-${headerHeight}`)};
  transition: top 0.3s;
  z-index: 99;
  background-color: ${Colors.lightBackground};
  padding: ${pxToRem(8)};
  display: flex;
  align-items: center;
  gap: 2rem;
  box-shadow: 0px 4px 4px ${withOpacity(Colors.utilityDark, 0.25)};
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: ${pxToRem(120)};
`;

export const Title = styled.div`
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.medium};
  font-weight: ${FontWeight.bold};

  &:last-child {
    color: ${Colors.primary};
  }
`;

export const AccordionNavbar = styled(AccordionComponent)`
  position: absolute;
  left: 0;
  top: 0;

  background: ${Colors.lightBackground};
  padding: ${pxToRem(20)} ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(16)};
  border-radius: 0 0 ${pxToRem(10)} 0;

  svg {
    fill: ${Colors.font};
  }
`;

export const Accordion = styled(AccordionComponent)`
  position: absolute;
  right: ${pxToRem(8)};
  top: ${pxToRem(8)};

  background: ${Colors.lightBackground};
  padding: ${pxToRem(8)} ${pxToRem(16)};
  border-radius: ${pxToRem(10)};
`;

export const CurrentShopIcon = styled(CurrentShopSvg)`
  height: ${pxToRem(32)};
  width: ${pxToRem(35)};
`;

export const AccordionSummary = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${pxToRem(16)};

  span {
    font-family: ${Fonts.primary};
    font-weight: ${FontWeight.bold};
    display: flex;
    align-items: center;
  }
`;

export const AccordionDetails = styled.div`
  button {
    margin-top: ${pxToRem(16)};
    display: flex;
    align-items: center;
    font-family: ${Fonts.secondary};

    &.disconnect {
      font-family: ${Fonts.primary};
      font-weight: ${FontWeight.bold};
      margin: ${pxToRem(16)} auto 0 auto;
    }

    svg {
      margin-right: ${pxToRem(8)};
    }
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    font-family: ${Fonts.secondary};
    font-size: ${FontSizes.medium};
    font-weight: ${FontWeight.bold};
    text-transform: uppercase;
    color: ${Colors.primary};
    margin-top: ${pxToRem(24)};
  }
`;
