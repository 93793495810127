import convert from 'color-convert';
import { css } from 'styled-components';
import { Colors } from 'theme';

export const centerChildren = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const withOpacity = (color: Colors | Colors, opacity: number): string => {
  const [red, green, blue] = convert.hex.rgb(color);

  return `rgba(${red},${green},${blue}, ${opacity})`;
};

/**
 * Convert a size from pixels to root em
 * @param sizeInPixels Multiple of 8 ideally
 * @returns size in rem
 */
export const pxToRem = (sizeInPixels: number): string => {
  const sizeInRem = sizeInPixels / 16;
  return `${sizeInRem}rem`;
};
