import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CategorySuggestionField } from 'components/SuggestionField/Category';
import { StringSuggestionField } from 'components/SuggestionField/String';
import { CatalogueContext } from 'contexts/Catalogue';
import { Method, useApi } from 'service/api';
import { Category } from 'utils/dto';
import { CreateProduct } from 'utils/api-dto';

import { CreateCategories } from './CreateCategories';
import { ImagesSection } from './ImagesSection';
import { Component, SubmitButton } from './styles';
import { getProductToCreateProduct, productFormToCreateProduct } from './content';

export const CreateProductModal = () => {
  const {
    productInModal: defaultProduct,
    rows,
    setProductInModal,
    refetchCatalogue,
  } = useContext(CatalogueContext);

  const { t } = useTranslation();

  const formHookMethods = useForm<CreateProduct>({
    defaultValues: defaultProduct && getProductToCreateProduct(defaultProduct),
  });

  const isEdition = !!defaultProduct?.id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = formHookMethods;

  const { shopId }: { shopId: string } = useParams();

  const { data: categories = [] } = useApi<Category[]>([`shop/${shopId}/categories`], Method.GET);

  const { sendRequest, isLoading, status, resetResponse } = useApi(
    [isEdition ? `shop/${shopId}/product/${defaultProduct.id}` : `products`],
    Method.POST
  );

  const {
    data: newCategoryId,
    sendRequest: createCategory,
    status: createCategoryStatus,
  } = useApi<string>([`shop/${shopId}/categories`], Method.POST);

  useEffect(() => {
    if (status === 201) {
      resetResponse();
      setProductInModal(undefined);
      refetchCatalogue();
    }
  }, [status]);

  const subcategoriesSuggestions = [...new Set(rows.map((p) => p.subcategory))].filter(
    (sc) => typeof sc === 'string'
  ) as string[];

  const createProduct = (formData: CreateProduct) => {
    const productToCreate = productFormToCreateProduct(formData, shopId);

    sendRequest(productToCreate);
  };

  useEffect(() => {
    if (createCategoryStatus !== 201) return;

    const formValues = getValues();
    createProduct({ ...formValues, categoryId: newCategoryId });
  }, [createCategoryStatus]);

  const onSubmit = async (formData: CreateProduct) => {
    const isNewCategory = !categories.some(
      (c) => c.name.toLocaleLowerCase() === formData.category.name.toLocaleLowerCase()
    );

    if (isNewCategory) return createCategory({ name: formData.category.name });

    return createProduct(formData);
  };

  return (
    <FormProvider {...formHookMethods}>
      <Component onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder={`${t('product.name')} *`}
          className={errors.name ? 'error' : ''}
          autoComplete="off"
          {...register('name', { required: true })}
        />
        <div className="price">
          <input
            type="number"
            placeholder={`${t('product.price')} *`}
            className={errors.price ? 'error' : ''}
            step="0.01"
            {...register('price', {
              required: true,
              valueAsNumber: true,
              min: 0,
            })}
          />
          €
        </div>
        <CategorySuggestionField
          placeholder={`${t('product.category')} *`}
          categories={categories}
        />
        <StringSuggestionField
          suggestions={subcategoriesSuggestions}
          placeholder={t('product.subCategory')}
          accessor="subcategory"
        />
        <input
          type="text"
          placeholder={t('product.description')}
          className="full-width"
          {...register('description')}
        />

        <ImagesSection />

        <CreateCategories />

        <SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? '...' : <>{isEdition ? t('editProduct') : t('createProduct')}</>}
        </SubmitButton>
      </Component>
    </FormProvider>
  );
};
