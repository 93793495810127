import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext, IsServedStatus, defaultContextValue } from 'contexts/Filter';
import { Colors } from 'theme';
import { Container, Button, ServedIcon } from './styles';

export const FilterButton = () => {
  const [currentStatus, setCurrentStatus] = useState(defaultContextValue.isServedFilter);
  const { t } = useTranslation();

  const { setIsServedFilter } = useContext(FilterContext);

  useEffect(() => setIsServedFilter(currentStatus), [currentStatus]);

  return (
    <Container>
      {[IsServedStatus.All, IsServedStatus.IsNotServed, IsServedStatus.IsServed].map((status) => (
        <Button
          key={status}
          onClick={() => setCurrentStatus(status)}
          isSelected={currentStatus === status}
          bg={currentStatus === status ? Colors.secondary : Colors.utilityDark}
        >
          <ServedIcon />
          {t(`status.${status}`)}
        </Button>
      ))}
    </Container>
  );
};
