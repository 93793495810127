import styled from 'styled-components';
import { Colors, Fonts, FontSizes, FontWeight } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { BorderType } from '../OrderModals/content';

type ProductType = {
  bottomBorder: BorderType;
  isSelected: boolean;
  isClickable: boolean;
  isMuted: boolean;
};

const getBottomBorder = (borderType: BorderType) => {
  switch (borderType) {
    case BorderType.None:
      return '0';
    case BorderType.Secondary:
      return `1px solid ${Colors.secondary}`;
    default:
      return `1px solid ${Colors.primaryLighter}`;
  }
};

export const Product = styled.button<ProductType>`
  width: 100%;
  border-bottom: ${(props) => getBottomBorder(props.bottomBorder)};
  padding: ${pxToRem(16)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(24)};
  pointer-events: ${(props) => (props.isClickable ? 'auto' : 'none')};
  background: ${(props) => (props.isSelected ? Colors.primaryLighter : 'transparent')};
  opacity: ${(props) => (props.isMuted ? 0.3 : 1)};
`;

export const Name = styled.p`
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.xBold};
  text-transform: uppercase;
  color: ${Colors.primary};
  max-width: 80%;
`;

export const ProductInformation = styled.div`
  color: ${Colors.primary};
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.bold};
`;

export const Price = styled.div`
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  color: ${Colors.utilityDark};
  font-weight: ${FontWeight.light};
  white-space: nowrap;

  display: flex;
  align-items: center;
`;

export const Quantity = styled.span`
  padding: 0 ${pxToRem(8)};

  color: ${Colors.secondary};
  font-family: ${Fonts.secondary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.bold};
`;

export const OptionBox = styled.ul`
  margin: 0 ${pxToRem(16)};
  padding-top: ${pxToRem(4)};
`;

export const Option = styled.li`
  font-size: ${FontSizes.regular};
  margin-top: ${pxToRem(4)};
`;
export const IncrementButton = styled.button`
  background: ${Colors.secondary};
  border-radius: 50%;
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  min-height: ${pxToRem(24)};
  min-width: ${pxToRem(24)};
  font-size: ${pxToRem(16)};
  margin-left: ${pxToRem(12)};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${Fonts.primary};
  color: ${Colors.utilityBright};
  font-weight: ${FontWeight.bold};
`;
