import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { formatPrice } from 'utils/price';
import { SummaryProduct } from 'components/SummaryProduct';
import { CenteredModal, Direction } from 'components/CenteredModal';
import { BodyBox } from './styles';
import { Buttons } from './Buttons';
import { PaymentDataBox, Separator } from '../common-styles';

export const OrderSummaryModal = () => {
  const {
    relevantOrder: order,
    closeModal,
    displayedModal,
    isEditMode,
  } = useContext(OrderModalContext);

  const { t } = useTranslation();

  if (!order) return null;

  return (
    <CenteredModal
      isModalOpen={displayedModal === ModalType.Summary}
      toggleModal={closeModal}
      direction={Direction.Left}
      title={t('orderSummary')}
      isSummaryModal
    >
      <BodyBox>
        {order &&
          order.orderedProducts.map((product) => (
            <SummaryProduct
              key={product.id}
              product={product}
              usage={isEditMode ? 'edition' : 'information'}
            />
          ))}

        <PaymentDataBox>
          <div className="bottom-border">
            <p>{t('orderTotal')} </p>
            <p data-cy="total-price">{order.totalPrice && formatPrice(order.totalPrice)}</p>
          </div>
          <Separator />
          <div>
            <p>{t('leftToPay')}</p>
            <p>{order.totalPrice && formatPrice(order.leftToPay)}</p>
          </div>
        </PaymentDataBox>

        <Buttons />
      </BodyBox>
    </CenteredModal>
  );
};
