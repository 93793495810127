import React from 'react';
import { Colors } from 'theme';
import { Loader } from './styles';

type LoadingWheelProps = {
  diameter?: number;
  colors?: {
    primary: string;
    secondary: string;
  };
};

const defaultDiameter = 64;
const defaultColors = { primary: Colors.primary, secondary: Colors.primaryLighter };

export const LoadingWheel = (props: LoadingWheelProps) => {
  const { diameter = defaultDiameter, colors = defaultColors } = props;

  return <Loader diameter={diameter} colors={colors} />;
};

LoadingWheel.defaultProps = {
  diameter: defaultDiameter,
  colors: defaultColors,
};
