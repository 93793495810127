import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 5rem;
`;

type Props = { bg: Colors };

export const Status = styled.p<Props>`
  background: ${(props) => props.bg};

  width: ${pxToRem(97)};
  height: ${pxToRem(25)};
  border-radius: 3px;
  color: ${Colors.utilityBright} !important;
  padding-top: ${pxToRem(5)};
  text-align: center;
`;
