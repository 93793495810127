import styled from 'styled-components';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const Component = styled.label`
  position: relative;
  display: inline-block;
  width: ${pxToRem(60)};
  height: ${pxToRem(34)};

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Colors.error};
    -webkit-transition: 200ms;
    transition: 200ms;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: ${pxToRem(26)};
    width: ${pxToRem(26)};
    left: 4px;
    bottom: 4px;
    background-color: ${Colors.utilityBright};
    -webkit-transition: 200ms;
    transition: 200ms;
  }

  input:checked + .slider {
    background-color: ${Colors.validate};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(${pxToRem(26)});
    -ms-transform: translateX(${pxToRem(26)});
    transform: translateX(${pxToRem(26)});
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 99px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
