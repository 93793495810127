import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useApi, Method } from 'service/api';
import { CenteredModal } from 'components/CenteredModal';
import { CategoriesContext, EMPTY_CATEGORY } from 'contexts/Categories';

import { Content, ButtonsBox, Button, DeleteButton } from './styles';

export const EditCategoryModal = () => {
  const { t } = useTranslation();

  const { categoryToEdit, setDisplayModal, refreshCategories, displayModal, setCategoryToEdit } =
    useContext(CategoriesContext);

  const [name, setName] = useState(categoryToEdit.name);

  const { shopId }: { shopId: string } = useParams();
  const {
    status: editionStatus,
    sendRequest: editCategories,
    isLoading: IsEditing,
    resetResponse: resetEditResponse,
  } = useApi([`shop/${shopId}/categories/edit`], Method.POST);
  const {
    status: createStatus,
    sendRequest: createCategory,
    isLoading: IsCreating,
    resetResponse: resetCreateResponse,
  } = useApi([`shop/${shopId}/categories`], Method.POST);

  const {
    status: deleteStatus,
    sendRequest: deleteCategory,
    isLoading: isDeleting,
    resetResponse: resetDeleteResponse,
  } = useApi([`shop/${shopId}/category/${categoryToEdit.id}`], Method.DELETE);

  const closeModal = () => {
    setDisplayModal(false);
    setCategoryToEdit(EMPTY_CATEGORY);
  };

  useEffect(() => {
    if (createStatus === 201 || editionStatus === 202 || deleteStatus === 202) {
      closeModal();
      refreshCategories();

      resetCreateResponse();
      resetEditResponse();
      resetDeleteResponse();
    }
  }, [createStatus, editionStatus, deleteStatus]);

  const isEditMode = !!categoryToEdit?.id;

  const onSubmit = () => {
    if (isEditMode) return editCategories([{ id: categoryToEdit?.id, name }]);

    return createCategory({ name });
  };

  const hasJoinedProducts = categoryToEdit.nbProducts > 0;
  const displayDeleteButton = isEditMode && !hasJoinedProducts;

  return (
    <CenteredModal
      title={isEditMode ? t('categories.edit') : t('categories.create')}
      isModalOpen={displayModal}
      toggleModal={closeModal}
    >
      <Content>
        <input
          type="text"
          placeholder={t('categories.namePlaceholder')}
          autoComplete="off"
          defaultValue={categoryToEdit?.name || ''}
          onChange={(e) => setName(e.target.value)}
        />

        <ButtonsBox>
          <Button onClick={closeModal} isEmpty>
            {t('cancel')}
          </Button>

          <Button type="button" onClick={onSubmit} disabled={IsCreating || IsEditing}>
            {isEditMode ? t('edit') : t('create')}
          </Button>
        </ButtonsBox>

        {displayDeleteButton && (
          <DeleteButton type="button" onClick={() => deleteCategory()} disabled={isDeleting}>
            {t('categories.delete')}
          </DeleteButton>
        )}

        {hasJoinedProducts && (
          <p>
            {t('categories.linkedCategories', {
              nbProducts: categoryToEdit.nbProducts,
              count: categoryToEdit.nbProducts,
            })}
          </p>
        )}
      </Content>
    </CenteredModal>
  );
};
