import styled from 'styled-components';
import { ReactComponent as DeleteCrossSvg } from 'assets/DeleteCross.svg';
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';

export const CancelCrossWrapper = styled.button`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  border-radius: 50%;
  position: absolute;
  right: ${pxToRem(8)};
  top: ${pxToRem(8)};
`;

export const CancelCrossIcon = styled(DeleteCrossSvg)`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  fill: ${Colors.font};
`;
