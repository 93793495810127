import styled from 'styled-components';
import { Colors } from 'theme';
import { DeleteCta } from 'theme/globals';
import { pxToRem } from 'utils/style-utils';

export const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin-left: ${pxToRem(10)};
  }
`;

export const CategoryBox = styled.div`
  background: rgba(0, 33, 87, 0.1);
  grid-column: span 2;
  padding: ${pxToRem(24)} ${pxToRem(16)};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(16)};
`;

export const DeleteButton = styled(DeleteCta)`
  grid-column: span 2;
`;

export const RangeBox = styled.div`
  display: flex;

  flex-direction: column;

  input[type='range'] {
    all: unset;
    background: ${Colors.primaryLighter};
    border-radius: 99px;
    height: ${pxToRem(9)};
    margin-top: ${pxToRem(9)};
  }

  p {
    margin-top: ${pxToRem(9)};
    opacity: 0.5;
  }
`;

export const GridOption = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  justify-content: space-evenly;
  column-gap: 2rem;
`;
