import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OrdersContext } from 'contexts/Orders';
import { useApi, Method } from 'service/api';
import { Order } from 'utils/dto';
import { LoadingWheel } from 'components/LoadingWheel';
import { ModalType, OrderModalContext } from 'contexts/OrderModal';
import { mergeProducts } from 'utils/product';
import {
  ComponentBox,
  Card,
  StickersBox,
  Sticker,
  ServedIcon,
  NeedHelpIcon,
  NeedsPaymentIcon,
} from './styles';
import { TimerTableNumber } from './TimerTableNumber';
import { ProductList } from './ProductList';
import { ButtonsSection } from './ButtonsSection';
import { CancelCross } from './CancelCross';
import { Instructions } from './Instructions';

type OrderTicketProps = { order: Order };

export const OrderTicket = ({ order }: OrderTicketProps) => {
  const { shopId }: { shopId: string } = useParams();

  const products = mergeProducts(order.orderedProducts, true);

  const servedProducts = products.filter((orderedProduct) => orderedProduct.isServed);
  const notServedProducts = products.filter((orderedProduct) => !orderedProduct.isServed);

  const { refreshOrders } = useContext(OrdersContext);
  const { setRelevantOrder, toggleModal } = useContext(OrderModalContext);

  const {
    sendRequest: deleteNeedsHelp,
    isLoading: isLoadingNeedsHelp,
    status: statusNeedsHelp,
    resetResponse: resetResponseNeedsHelp,
  } = useApi([`shop/${shopId}/table/${order.tableNumber}/help`], Method.DELETE);

  const {
    sendRequest: deleteNeedsPayment,
    isLoading: isLoadingNeedsPayment,
    status: statusNeedsPayment,
    resetResponse: resetResponseNeedsPayment,
  } = useApi([`shop/${shopId}/order/${order.id}/needs-payment`], Method.POST);

  useEffect(() => {
    if (statusNeedsHelp === 202) {
      resetResponseNeedsHelp();
      refreshOrders();
    }
  }, [statusNeedsHelp]);

  useEffect(() => {
    if (statusNeedsPayment === 202) {
      resetResponseNeedsPayment();
      refreshOrders();
    }
  }, [statusNeedsPayment]);

  let backgroundProducts = servedProducts;
  let foregroundProducts = notServedProducts;

  // backgroundProducts serves as the whole order when none or all products are served
  if (backgroundProducts.length === 0) {
    backgroundProducts = foregroundProducts.slice();
    foregroundProducts = [];
  }

  const allProductsServed = order.isServed;

  const handleCancelClick = () => {
    toggleModal(ModalType.Cancel);
    setRelevantOrder(order);
  };

  return (
    <ComponentBox data-cy="product-card">
      <Card isMuted={foregroundProducts.length > 0}>
        <CancelCross onClick={handleCancelClick} />
        <TimerTableNumber
          tableNumber={order.tableNumber}
          creationDate={order.createdAt}
          isServed={false}
        />

        <ProductList orderedProducts={backgroundProducts} />
        {foregroundProducts.length === 0 && (
          <>
            <ButtonsSection allProductsServed={allProductsServed} order={order} />
            {order.specificInstructions && <Instructions text={order.specificInstructions} />}
          </>
        )}
      </Card>

      {foregroundProducts.length > 0 && (
        <Card isInForeground>
          <ProductList orderedProducts={foregroundProducts} />
          <ButtonsSection allProductsServed={allProductsServed} order={order} />
          {order.specificInstructions && <Instructions text={order.specificInstructions} />}
        </Card>
      )}

      <StickersBox>
        {allProductsServed && (
          <Sticker>
            <ServedIcon data-cy="serve-icon" />
          </Sticker>
        )}

        {order.needsHelp && (
          <Sticker
            color="error"
            data-cy="help-img"
            isHelpType
            onClick={() => deleteNeedsHelp()}
            disabled={isLoadingNeedsHelp}
          >
            {isLoadingNeedsHelp ? <LoadingWheel diameter={8} /> : <NeedHelpIcon />}
          </Sticker>
        )}

        {order.needsPayment && (
          <Sticker
            color="needsPayment"
            data-cy="needs-payment-img"
            isHelpType
            onClick={() => deleteNeedsPayment()}
            disabled={isLoadingNeedsPayment}
          >
            {isLoadingNeedsPayment ? <LoadingWheel diameter={8} /> : <NeedsPaymentIcon />}
          </Sticker>
        )}
      </StickersBox>
    </ComponentBox>
  );
};
