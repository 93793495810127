import React, { HTMLProps } from 'react';
import { useWatch } from 'react-hook-form';
import { useSuggestionField } from './hook';
import { Component, SuggestionsBox } from './styles';

type Props = {
  suggestions: string[];
  accessor: string;
} & HTMLProps<HTMLInputElement>;

export const StringSuggestionField = ({
  suggestions: allSuggestions = [],
  accessor,
  ...rest
}: Props) => {
  const restProps: HTMLProps<HTMLInputElement> = rest;

  const { displaySuggestions, ref, setDisplaySuggestions, inputProps, formMethods } =
    useSuggestionField(false);

  const { control, setValue, register } = formMethods;

  const value: string = useWatch({ control, name: accessor }) || '';

  const onSuggestionClick = (suggestion: string) => {
    setDisplaySuggestions(false);
    setValue(accessor, suggestion);
  };

  const suggestions = allSuggestions.filter((suggestion) =>
    suggestion.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );

  return (
    <Component>
      <input {...inputProps} {...restProps} {...register(accessor)} />

      {displaySuggestions && suggestions.length > 0 && (
        <SuggestionsBox ref={ref}>
          {suggestions.map((suggestion) => (
            <button type="button" onClick={() => onSuggestionClick(suggestion)} key={suggestion}>
              {suggestion}
            </button>
          ))}
        </SuggestionsBox>
      )}
    </Component>
  );
};
