import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/price';
import { OrderModalContext, ModalType } from 'contexts/OrderModal';
import { OrdersContext } from 'contexts/Orders';
import { CenteredModal } from 'components/CenteredModal';
import { useApi, Method } from 'service/api';
import { LoadingWheel } from 'components/LoadingWheel';
import { useParams } from 'react-router-dom';
import { Colors } from 'theme';
import { Cta } from 'theme/globals';
import { ComponentBox, ButtonsSection } from './style';
import { PaymentDataBox, Separator, ProgressBar } from '../common-styles';

export const InvoiceModal = () => {
  const {
    displayedModal,
    relevantOrder,
    closeModal,
    toggleModal,
    selectedProducts,
    productsToPayPrice,
    clearSelectedProduct,
  } = useContext(OrderModalContext);
  const { refreshOrders } = useContext(OrdersContext);
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const { shopId }: { shopId: string } = useParams();

  const { sendRequest, status, resetResponse, error, isLoading } = useApi(
    [`shop/${shopId}/order/${relevantOrder?.id}/arbitrary-payment`],
    Method.POST
  );
  const { sendRequest: sendSelectedProducts } = useApi(
    [`shop/${shopId}/order/${relevantOrder?.id}/payment`],
    Method.POST
  );

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      resetResponse();
      refreshOrders();
      closeModal();
    };

    if (status === 201) {
      handlePaymentSuccess();
    }
  }, [status]);

  if (!relevantOrder) return null;

  const handleSendRequest = () => {
    if (productsToPayPrice) {
      sendSelectedProducts({
        email,
        productsToPayId: selectedProducts.map((product) => product.id),
        paymentMean: 'CB',
      });
      clearSelectedProduct();
      closeModal();
    } else {
      sendRequest({ email, paymentAmount: relevantOrder.leftToPay, paymentMean: 'UNKNOWN' });
    }
  };

  return (
    <CenteredModal
      isModalOpen={displayedModal === ModalType.Invoice}
      toggleModal={closeModal}
      title={t('paymentValidation')}
      noPadding
    >
      <>
        <ProgressBar progression="80%" />

        <ComponentBox data-cy="product-modal">
          <h1>{t('invoiceEmail')}</h1>
          <input
            id="mail"
            placeholder="Adresse e-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <PaymentDataBox>
            <div>
              <p>{t('orderTotal')}</p>
              <p>{formatPrice(relevantOrder.totalPrice)}</p>
            </div>
            <Separator />
            <div>
              <p>{t('payment')}</p>
              <p>
                {productsToPayPrice > 0
                  ? formatPrice(productsToPayPrice)
                  : formatPrice(relevantOrder.leftToPay)}
              </p>
            </div>
          </PaymentDataBox>

          <ButtonsSection>
            <Cta isEmpty onClick={() => toggleModal(ModalType.Summary)}>
              {t('cancel')}
            </Cta>
            <Cta onClick={() => handleSendRequest()}>
              {isLoading ? (
                <LoadingWheel
                  diameter={20}
                  colors={{ primary: Colors.primary, secondary: Colors.utilityBright }}
                />
              ) : (
                t('validateOrder')
              )}
            </Cta>
            {error && t('errorOccurred')}
          </ButtonsSection>
        </ComponentBox>
      </>
    </CenteredModal>
  );
};
