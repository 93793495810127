import { LoadingWheel } from 'components/LoadingWheel';
import { CatalogueContext } from 'contexts/Catalogue';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { Method, useApi } from 'service/api';
import { Product } from 'utils/dto';

import { Component, Select } from './styles';

type Props = {
  product: Product;
};

export const AvailabilityDropDown = ({ product }: Props) => {
  const { refetchCatalogue } = useContext(CatalogueContext);

  const { shopId }: { shopId: string } = useParams();

  const { sendRequest, isLoading } = useApi([`shop/${shopId}/product/${product.id}`], Method.POST);

  const [value, setValue] = useState(product.isAvailable);

  // TODO: Component should not be responsible for the api logic, his parent should
  // this needs only 2 props : loading and isOn

  useEffect(() => {
    if (value === product.isAvailable) return;

    sendRequest({
      ...product,
      shopId,
      isAvailable: value,
    }).then(() => {
      setTimeout(() => {
        refetchCatalogue();
      }, 300);
    });
  }, [value]);

  useEffect(() => {
    setValue(product.isAvailable);
  }, [product]);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => setValue(e.target.value === 'true');

  return (
    <Component>
      {isLoading ? (
        <LoadingWheel diameter={24} />
      ) : (
        <Select onChange={onChange} value={value.toString()} isSuccess={value}>
          <option value={value.toString()}>{value ? 'Oui' : 'Non'}</option>
          <option value={value ? 'false' : 'true'}>{!value ? 'Oui' : 'Non'}</option>
        </Select>
      )}
    </Component>
  );
};
