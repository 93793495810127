import React, { createContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router';
import useSound from 'use-sound';
import newOrderSound from 'assets/double-bell.mp3';
import { Method, useApi } from 'service/api';
import { apiOrdersToOrder, getNeedHelpList } from 'utils/order';
import { Order } from 'utils/dto';
import { getOrderedProductsQuantities } from 'utils/product';
import { hasSameItems } from 'utils/utils';

export interface OrdersContextType {
  orders: Order[];
  unMergedOrder: Order[];
  setUnMergedOrder: Dispatch<SetStateAction<Order[]>>;
  refreshOrders: () => void;
  error: boolean;
}

export const defaultContextValue: OrdersContextType = {
  orders: [],
  refreshOrders: () => null,
  unMergedOrder: [],
  setUnMergedOrder: () => null,
  error: false,
};

export const OrdersContext = createContext<OrdersContextType>(defaultContextValue);

export const OrdersContextProvider = ({ children }: { children: JSX.Element }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [unMergedOrder, setUnMergedOrder] = useState<Order[]>([]);

  const { shopId }: { shopId: string } = useParams();

  const [playSound] = useSound(newOrderSound);

  const {
    data,
    sendRequest: refreshOrders,
    error,
  } = useApi([`shop/${shopId}/order-board`], Method.GET);

  /* refresh orders every 2 seconds  */
  useEffect(() => {
    const intervalId = setTimeout(() => {
      refreshOrders();
    }, 2000);

    return () => clearTimeout(intervalId);
  });

  /* update context if api data are different */
  useEffect(() => {
    const apiOrders: Order[] = apiOrdersToOrder(data || [], true);
    const apiOrdersUnMerged: Order[] = apiOrdersToOrder(data || [], false);

    const contextNeedUpdate = JSON.stringify(apiOrders) !== JSON.stringify(orders);

    if (contextNeedUpdate) {
      const apiProductsCount = getOrderedProductsQuantities(apiOrders);
      const oldProductsCount = getOrderedProductsQuantities(orders);

      if (apiProductsCount > oldProductsCount || apiOrders.length > orders.length) {
        playSound();
      } else {
        const apiNeedHelpList = getNeedHelpList(apiOrders);
        const oldNeedHelpList = getNeedHelpList(orders);

        if (!hasSameItems(apiNeedHelpList, oldNeedHelpList)) {
          playSound();
        }
      }
      setUnMergedOrder(apiOrdersUnMerged);
      setOrders(apiOrders);
    }
  }, [data]);

  const FilterContextValue: OrdersContextType = {
    orders: orders || [],
    unMergedOrder: unMergedOrder || [],
    setUnMergedOrder,
    refreshOrders,
    error: !!error,
  };

  return <OrdersContext.Provider value={FilterContextValue}>{children}</OrdersContext.Provider>;
};
