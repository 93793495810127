import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switch } from 'components/Switch';
import { CircularCta } from 'theme/globals';
import { CreateOptionCategory } from 'utils/api-dto';

import { SwitchBox, CategoryBox, RangeBox, GridOption, DeleteButton } from './styles';
import { CreateOptions } from '../CreateOptions';
import { AddOption } from '../CreateOptions/styles';

const EMPTY_CATEGORY: CreateOptionCategory = {
  title: '',
  options: [],
  minQuantity: 0,
  maxQuantity: 0,
};

export const CreateCategories = () => {
  const { register, control } = useFormContext();

  const { t } = useTranslation();

  const optionCategories = useWatch({ control, name: 'optionCategories' });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'optionCategories',
  });

  return (
    <>
      <GridOption>
        <SwitchBox>
          {t('product.availability')}
          <Switch name="isAvailable" />
        </SwitchBox>

        <SwitchBox>
          {t('product.isAlcohol')}
          <Switch name="isAlcohol" />
        </SwitchBox>

        <AddOption type="button" onClick={() => append(EMPTY_CATEGORY)}>
          {t('addCategory')}
          <CircularCta type="button">+</CircularCta>
        </AddOption>
      </GridOption>

      {fields.map((category, index) => (
        <CategoryBox key={category.id}>
          <input
            type="text"
            placeholder="Titre"
            {...register(`optionCategories[${index}].title` as 'optionCategories')}
          />

          <CreateOptions categoryIndex={index} />

          <RangeBox>
            {t('product.minQuantity')} -{' '}
            {optionCategories[index] && optionCategories[index].minQuantity}
            <input
              type="range"
              min="0"
              max={optionCategories[index]?.options.length}
              step="1"
              {...register(`optionCategories[${index}].minQuantity` as 'optionCategories', {
                valueAsNumber: true,
              })}
            />
            {optionCategories[index]?.minQuantity <= 0 && <p> {t('notRequiredChoice')}</p>}
          </RangeBox>

          <RangeBox>
            {t('product.maxQuantity')} -{' '}
            {optionCategories[index] && optionCategories[index].maxQuantity}
            <input
              type="range"
              min="0"
              max={optionCategories[index]?.options.length}
              step="1"
              {...register(`optionCategories[${index}].maxQuantity` as 'optionCategories', {
                valueAsNumber: true,
              })}
            />
          </RangeBox>

          <DeleteButton type="button" onClick={() => remove(index)}>
            {t('deleteCategory')}
          </DeleteButton>
        </CategoryBox>
      ))}
    </>
  );
};
