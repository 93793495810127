import React, { useState, useEffect } from 'react';
import { calculateTimeToNow } from 'utils/time';
import { ComponentBox, TimerBox, TableNumber } from './styles';

type TimerTableNumberProps = {
  tableNumber: number;
  creationDate: Date;
  isServed: boolean;
};

export const TimerTableNumber = ({
  tableNumber,
  creationDate,
  isServed,
}: TimerTableNumberProps) => {
  const [timer, setTimer] = useState(calculateTimeToNow(creationDate));

  useEffect(() => {
    if (isServed) return;

    const updateTimer = setTimeout(() => {
      setTimer(calculateTimeToNow(creationDate));
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(updateTimer);
  });

  return (
    <ComponentBox>
      {!isServed && <TimerBox>{timer}</TimerBox>}
      <TableNumber data-cy="table-number" padding={tableNumber.toString().length >= 3}>
        {tableNumber}
      </TableNumber>
    </ComponentBox>
  );
};
