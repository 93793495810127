import styled from 'styled-components';
import { ReactComponent as ServeIconSvg } from 'assets/ServeIcon.svg'; // Button
import { ReactComponent as PayIconSvg } from 'assets/PayIcon.svg'; // Button
import { Colors } from 'theme';
import { pxToRem } from 'utils/style-utils';
import { Cta } from 'theme/globals';

export const ButtonsBox = styled.div`
  background-color: ${Colors.lightBackground};
  padding: ${pxToRem(8)} ${pxToRem(16)} ${pxToRem(16)};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${pxToRem(16)};

  p {
    grid-column: span 2;
    text-align: center;
  }
`;

export const Button = styled(Cta)`
  height: ${pxToRem(40)};
`;

export const ServeIcon = styled(ServeIconSvg)`
  height: ${pxToRem(32)};
  width: ${pxToRem(44)};
`;

export const PayIcon = styled(PayIconSvg)`
  height: ${pxToRem(30)};
  width: ${pxToRem(32)};
`;
