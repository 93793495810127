import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CatalogueContext } from 'contexts/Catalogue';
import { CreateProductOption } from 'utils/api-dto';
import { Switch } from 'components/Switch';
import { StringSuggestionField } from 'components/SuggestionField/String';
import { CircularCta } from 'theme/globals';

import { SwitchBox } from '../CreateCategories/styles';
import { OptionBox, AddOption, RowBox, ColumnBox } from './styles';

type Props = { categoryIndex: number };

const EMPTY_OPTION: CreateProductOption = {
  name: '',
  additionalPrice: undefined,
  isAvailable: true,
  isAlcohol: false,
};

export const CreateOptions = ({ categoryIndex }: Props) => {
  const { register, control } = useFormContext();

  const { rows } = useContext(CatalogueContext);

  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `optionCategories.${categoryIndex}.options`,
  });

  const optionsSuggestions: string[] = [
    ...new Set(
      rows
        .map((product) =>
          product.optionCategories.map((category) => category.options.map((option) => option.name))
        )
        .flat(3)
    ),
  ];

  return (
    <>
      <AddOption type="button" onClick={() => append(EMPTY_OPTION)}>
        {t('addOption')}
        <CircularCta type="button">+</CircularCta>
      </AddOption>

      {fields.map((category, index) => (
        <OptionBox key={category.id}>
          <ColumnBox>
            <StringSuggestionField
              suggestions={optionsSuggestions}
              placeholder={`Option ${index + 1} *`}
              accessor={
                `optionCategories[${categoryIndex}].options[${index}].name` as 'optionCategories'
              }
              required
            />
            <input
              type="number"
              placeholder={t('additionalPricePlaceholder')}
              step="0.01"
              {...register(
                `optionCategories[${categoryIndex}].options[${index}].additionalPrice` as 'optionCategories',
                { valueAsNumber: true }
              )}
            />
          </ColumnBox>

          <RowBox>
            <SwitchBox>
              {t('product.availability')}
              <Switch
                name={
                  `optionCategories[${categoryIndex}].options[${index}].isAvailable` as 'optionCategories'
                }
              />
            </SwitchBox>

            <CircularCta isSmall type="button" onClick={() => remove(index)}>
              X
            </CircularCta>
          </RowBox>
        </OptionBox>
      ))}
    </>
  );
};
