import React, { useContext } from 'react';
import { CategoriesContext } from 'contexts/Categories';
import { CategoryCard } from './CategoryCard';
import { List } from './styles';

type Props = {
  sortBy: 'frontOrder' | 'boOrder';
};

export const CategoriesSortableList = ({ sortBy }: Props) => {
  const { categories = [] } = useContext(CategoriesContext);

  categories.sort((a, b) => a[sortBy] - b[sortBy]);

  return (
    <List>
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} sortBy={sortBy} />
      ))}
    </List>
  );
};
