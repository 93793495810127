import styled from 'styled-components';
import { Colors } from 'theme';
import { Cta, DeleteCta } from 'theme/globals';
import { pxToRem } from 'utils/style-utils';

export const Content = styled.form`
  min-width: ${pxToRem(280)};

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid ${Colors.utilityDark};
    margin: ${pxToRem(24)} 0;
    padding: ${pxToRem(4)} 0;
  }

  p {
    color: ${Colors.fontLighter};
    font-style: italic;
    text-align: center;
    font-size: ${pxToRem(14)};
    margin-top: ${pxToRem(24)};
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  gap: ${pxToRem(24)};
`;

export const Button = styled(Cta)`
  width: 100%;
`;

export const DeleteButton = styled(DeleteCta)`
  margin-top: ${pxToRem(24)};
`;
