import { OrderedProductToEdit } from 'components/OrderModals/content';
import { Order, OrderedProduct } from './dto';

export const isCurrentProductEqual = (
  product: OrderedProduct,
  currentProduct: OrderedProduct,
  comparePayment: boolean
) =>
  JSON.stringify({
    ...product,
    id: '',
    quantity: 0,
    productIds: [],
    isPaid: comparePayment ? false : product.isPaid,
    orderedOptions: product.orderedOptions.sort(),
  }) ===
  JSON.stringify({
    ...currentProduct,
    id: '',
    quantity: 0,
    productIds: [],
    isPaid: comparePayment ? false : currentProduct.isPaid,
    orderedOptions: currentProduct.orderedOptions.sort(),
  });

/* Gather same products in one product with quantity sum */
export const mergeProducts = (products: OrderedProduct[], mergePayedTogether = false) =>
  products.reduce((accumulator: OrderedProduct[], currentProduct) => {
    const isAlreadyInAcc = !accumulator.some((product) =>
      isCurrentProductEqual(product, currentProduct, mergePayedTogether)
    );

    if (isAlreadyInAcc) {
      const similarProducts = products.filter((product) =>
        isCurrentProductEqual(product, currentProduct, mergePayedTogether)
      );

      const quantitiesSum = similarProducts
        .map((product) => product.quantity || 1)
        .reduce((partialSum, a) => partialSum + a, 0);

      const productIds = similarProducts.map((product) => product.id);

      const product: OrderedProduct = {
        ...currentProduct,
        quantity: quantitiesSum,
        productIds,
      };

      accumulator.push(product);
    }

    return accumulator;
  }, []);

export const computeQuantityEdition = (newProducts: OrderedProductToEdit[]) => {
  const idsToDelete: string[] = [];
  const productsToAdd: OrderedProduct[] = [];

  newProducts.forEach((newProduct) => {
    if (newProduct.newQuantity < 0) {
      idsToDelete.push(...newProduct.productIds.slice(newProduct.newQuantity));
    }
    if (newProduct.newQuantity > 0) {
      const orderedProduct = {
        productId: newProduct.orderedProductId,
        orderedOptions: newProduct.orderedOptionIds,
      };
      productsToAdd.push(...new Array(newProduct.newQuantity).fill(orderedProduct));
    }
  });

  return { productsToAdd, idsToDelete };
};

export const getOrderedProductsQuantities = (orders: Order[]) =>
  orders
    .map((o) => o.orderedProducts)
    .flat(1)
    .reduce((partialSum, o) => partialSum + o.quantity, 0);

export const sortProductsFunction = (a: OrderedProduct, b: OrderedProduct) => {
  // order by category
  if (a.category > b.category) return 1;
  if (a.category < b.category) return -1;

  // then by product id
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
};
