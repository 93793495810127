import styled from 'styled-components';
import { Colors, FontSizes, Fonts, FontWeight } from 'theme';
import { pxToRem, centerChildren } from 'utils/style-utils';

const TABLE_NUMBER_DIAMETER = 37;

export const ComponentBox = styled.div`
  ${centerChildren};
`;

export const TimerBox = styled.div`
  background-color: ${Colors.primaryLighter};
  border-radius: 999px 0 0 999px;
  margin-right: -${pxToRem(TABLE_NUMBER_DIAMETER / 2)};
  padding: ${pxToRem(4)} ${pxToRem(8)};

  color: ${Colors.lightFont};
  font-family: ${Fonts.primary};
  font-size: ${FontSizes.regular};
  font-weight: ${FontWeight.regular};
  width: ${pxToRem(60)};
`;

type TableNumberProps = {
  padding: boolean;
};

export const TableNumber = styled.div<TableNumberProps>`
  background-color: ${Colors.secondary};
  border-radius: 999px;
  height: ${pxToRem(TABLE_NUMBER_DIAMETER)};
  widty: ${pxToRem(TABLE_NUMBER_DIAMETER)};
  min-width: ${pxToRem(TABLE_NUMBER_DIAMETER)};
  padding: 0 ${(props) => (props.padding ? pxToRem(10) : '0px')};

  color: ${Colors.utilityBright};
  font-family: ${Fonts.primary};
  font-size: ${FontSizes.title};
  font-weight: ${FontWeight.bold};

  ${centerChildren}
`;
