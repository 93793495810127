import styled from 'styled-components';
import { centerChildren, pxToRem } from 'utils/style-utils';
import { Colors } from './colors';
import { Fonts, FontSizes, FontWeight } from './fonts';

export const Cta = styled.button<{ bg?: Colors; isEmpty?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.bg || Colors.primary};
  color: ${(props) => (props.isEmpty ? props.bg || Colors.primary : Colors.utilityBright)};
  background: ${(props) => (props.isEmpty ? 'none' : props.bg || Colors.primary)};
  border-radius: 5px;
  width: 100%;
  padding: ${pxToRem(8)} ${pxToRem(24)};

  text-align: center;
  font-size: ${FontSizes.medium};
  font-family: ${Fonts.secondary};
  font-weight: ${FontWeight.bold};
`;

export const DeleteCta = styled.button`
  color: ${Colors.error};
  margin: 0 auto;
  font-weight: ${FontWeight.bold};
  font-size: ${pxToRem(12)};
  text-align: center;
  font-family: ${Fonts.secondary};
`;

type CtaProps = {
  backgroundColor?: Colors;
  isSmall?: boolean;
};

export const CircularCta = styled.button<CtaProps>`
  height: ${(props) => pxToRem(props.isSmall ? 20 : 40)};
  width: ${(props) => pxToRem(props.isSmall ? 20 : 40)};
  min-height: ${(props) => pxToRem(props.isSmall ? 20 : 40)};
  min-width: ${(props) => pxToRem(props.isSmall ? 20 : 40)};

  background-color: ${(props) => props.backgroundColor || Colors.primary};
  border-radius: 50%;
  padding: ${pxToRem(4)};

  ${centerChildren}

  color: ${Colors.utilityBright};
  font-size: ${(props) => pxToRem(props.isSmall ? 16 : 24)};
  font-weight: ${FontWeight.bold};
`;
